import { Toast } from "antd-mobile";
import Web3 from "web3";
import { notification, Button } from 'antd';
import { getIntlKey } from "@/util/language";
import intl from "react-intl-universal";
import { switchChain } from './contract/bridge'
import Congratulations from '../asset/imgs/Modal/Congratulations.png'
import { debounce } from 'lodash';

const showConnected = debounce(() => {
  notification.success({
    message: intl.get(getIntlKey("connected")),
    description: intl.get(getIntlKey("connecteddes")),
    icon: <span><img alt='' src={Congratulations} /></span>,
  })
}, 2000)
var BN = Web3.utils.BN;

const RpcUrl = process.env.NODE_ENV === 'development' ? 'https://rinkeby.infura.io/v3/2b4d7823973942d0b4e6731db28068f5' : 'https://mainnet.infura.io/v3/2b4d7823973942d0b4e6731db28068f5';


export const getShowAddress = (address) => {
  if (address && address.length >= 10) {
    return address.substring(0, 6) + '...' + address.substring(address.length - 4);
  }
  return ''
}

export const toThousands = (num) => {
  // num = '1234567890.111';
  if (num == undefined) return '0';
  num = Web3.utils.fromWei(num);
  let numArr = num.toString().split('.');
  let int = numArr[0];
  let decmial = numArr[1] ? '.' + numArr[1] : '';
  let step = 0;
  // debugger
  let resultArr = [];
  for (let n = int.length - 1; n >= 0; n--) {
    resultArr.push(int[n]);
    step++;
    if (step >= 3 && n != 0) {
      resultArr.push(",");
      step = 0;
    }
  }
  return resultArr.reverse().join("") + decmial;
};

export const toThousandsRound = (num) => {
  // num = '1234567890.111';
  // console.log('toThousandsRound num: ', num)
  if (num == undefined || !num) return '0';
  num = Web3.utils.fromWei(num);
  let numArr = num.toString().split('.');
  let int = numArr[0];
  let decmial = numArr[1] ? '.' + numArr[1] : '';
  let step = 0;
  if (decmial !== '') {
    int = new BN(int).add(new BN('1')).toString()
  }
  // debugger
  let resultArr = [];
  for (let n = int.length - 1; n >= 0; n--) {
    resultArr.push(int[n]);
    step++;
    if (step >= 3 && n != 0) {
      resultArr.push(",");
      step = 0;
    }
  }
  return resultArr.reverse().join("");
};

export const toBillion = (amounWei) => {
  // console.log('to billin: ', amounWei);
  if (amounWei == undefined) amounWei = '0';
  // if (typeof amounWei == 'string') {
  //   amounWei = new BN(amounWei);
  // }
  let amount = Web3.utils.fromWei(amounWei);
  // console.log(amount);
  return Web3.utils.fromWei(amount, 'Gwei')
}

export const toEther = (amountWei) => {
  return Web3.utils.fromWei(amountWei);
}

export const calPercentage = (num1, num2) => {

  if (num1 == undefined || num2 == undefined || num1 == '0' || num2 == '0') {
    return "1";
  }
  // console.log('num1: ', num1)
  // console.log('num2: ', num2)
  num1 = Web3.utils.fromWei(num1);
  num2 = Web3.utils.fromWei(num2);

  if (num1.indexOf('.') != -1) {
    num1 = num1.substring(0, num1.indexOf('.'))
  }
  if (num2.indexOf('.') != -1) {
    num2 = num2.substring(0, num2.indexOf('.'))
  }
  // console.log('num1-1: ', num1)
  // console.log('num2-1: ', num2)
  let percentage = num2 / num1;
  // console.log('percentage fixed: ', percentage.toFixed(2));
  let r = 100 / percentage.toFixed(2);
  if (r < 20) {
    // r += parseInt(3);
  }
  r = r.toFixed(2);
  // console.log('final percent: ', r);
  return r;
}

function sleep(time) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve('sleepDone')
    }, time)
  })
}

export { sleep }

/**
 * get current login account
 */
export const getCurrentAccount = async () => {
  let address = "";
  if (window.ethereum) {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    address = accounts[0];
    return address;
  } else {
    let jsonObjStr = localStorage.getItem("walletconnect");
    let jsonObj = JSON.parse(jsonObjStr);
    return jsonObj?.accounts[0];
  }

}

export const checkChain = async (type) => {
  // if(!window.ethereum){
  //   return
  // }
  const web3 = new Web3(window.ethereum || RpcUrl);
  const chainId = await web3.eth.getChainId();
  const isDev = process.env.NODE_ENV === 'development';
  console.log(window.location.hash);
  console.log('isDev',isDev);
  console.log('chainId',chainId);
  if (window.location?.hash?.toLowerCase()?.includes('swap') || window.location?.hash?.toLowerCase()?.includes('ore')) {
    if (window.location?.hash?.toLowerCase()?.includes('etaoremeo')) {
      return;
    }
    console.log('chainId', chainId);
    if (isDev) {
      if (chainId !== 97) {
        notification.warn({
          duration: 6,
          message: intl.get(getIntlKey("pleaseswitchtobsctestnetworkfirst.")),
          btn: <Button className='switch-name' type="primary" onClick={() => { switchChain('BSC') }}>  switch chain </Button>
        })
        return false
      }
    } else if (chainId !== 56) {
      notification.warn({
        duration: 6,
        message: intl.get(getIntlKey("pleaseswitchtobscnetworkfirst.")),
        btn: <Button className='switch-name' type="primary" onClick={() => { switchChain('BSC') }}> switch chain </Button>
      })
      return false
    }
    return true;
  }
  
  if (window.location?.hash?.toLowerCase()?.includes('meo')) {
    return;
  }
  if (isDev) {
    if (chainId !== 4) {
      notification.warn({
        duration: 6,
        message: intl.get(getIntlKey("pleaseswitchtorinkebytestnetworkfirst.")),
        btn: <Button className='switch-name' type="primary" onClick={() => { switchChain('ETH') }}>  switch chain </Button>
      })
      return false
    }
  } else if (chainId !== 1) {
    notification.warn({
      duration: 6,
      message: intl.get(getIntlKey("pleaseswitchthechaintoethereummainnet.")),
      // description: intl.get(getIntlKey("pleaseswitchtorinkebytestnetworkfirst.")),
      btn: <Button className='switch-name' type="primary" onClick={() => { switchChain('ETH') }}> switch chain </Button>
    })
    return false
  }
  if (type === 'login') {
    showConnected()
  }
  return true
}


// compare string amount
export const compareStrUseBN = async (c1, c2) => {
  return new Promise((resolve, reject) => {
    try {
      c1 = String(c1);
      c2 = String(c2);
      if (c1.indexOf('.') != -1 || c2.indexOf(c2) != -1) {
        c1 = new BN(Web3.utils.toWei(c1, 'ether'));
        c2 = new BN(Web3.utils.toWei(c2, 'ether'));
      } else {
        c1 = new BN(c1);
        c2 = new BN(c2);
      }
      resolve(c1.cmp(c2));
    } catch (error) {
      reject('compare fail')
    }
  })
  

}