import meo from "../pages/meo";
import { BASE_URL } from "../service/config";
import { switchChain } from "../util/contract/bridge";
import axios from 'axios'
import { getMeoTxHashDetail } from "../service/idoService";
import { sleep } from "../util/common";

const getCurrentChainId = async () => {
  let chainId = 'none';
  if (window.ethereum) {
    chainId = await window.ethereum.request({ method: 'eth_chainId' });
  }
  return chainId;
}

const MeoModel = {
  namespace: 'meo',
  state: {
    chain: 'ETH',
    tokenCode: 'ETH',
    orderList: [],
    startInterval: false,
    meoTxHash: '',
    meoTxHashStatus: 'init',
    counter: 1,
  },

  effects: {
    *selectChain({ payload }, { call, put, select }) {
      let chainId = yield getCurrentChainId();
      let chain = payload.chain;
      let tokenCode = payload.tokenCode;
      let switchFlag = false;
      console.log(`chain: ${chain}, tokenCode: ${tokenCode}, chainId: ${chainId}`)
      if (chain === 'ETH' && (chainId !== '0x1' && chainId !== '0x4')) {
        switchFlag = yield switchChain('ETH');
      } else if (chain === 'BSC' && (chainId !== '0x38' && chainId !== '0x61')) {
        switchFlag = yield switchChain('BSC');
      }
      if (chain === 'ETH' && (tokenCode !== 'ETH' && tokenCode !== 'USDT')) {
        tokenCode = 'ETH';
      } else if (chain === 'BSC' && (tokenCode !== 'BNB' && tokenCode !== 'USDT' && tokenCode !== 'TBS')) {
        tokenCode = 'BNB';
      }
      console.log('final tokenCode: ', tokenCode)
      yield put({
        type: "setState",
        payload: {
          chain,
          tokenCode,
        },
      });

    },

    *getIdoList({ payload }, { call, put }) {
      let userAddress = payload.userAddress;
      if (!userAddress || userAddress === '') {
        return;
      }
      let userIdoListResp = yield call(axios.get, `${BASE_URL}ido/getUserIdoList`, {
        params: {
          userAddress,
        }
      });
      console.log('meo model getIdoList: ', userIdoListResp)
      if (userIdoListResp.status === 200 && userIdoListResp.data.code === 0) {
        yield put({
          type: "setState",
          payload: {
            orderList: userIdoListResp.data.data,
          },
        });
      }
    },

    *startQueryMeo({ payload }, { call, put, select }) {

      if (!payload || payload.meoTxHash === "" || payload.meoTxHash == undefined) {
        return // console.log("start interval buy land wrong");
      }
      console.log('enter startQueryMeo')
      yield put({
        type: "setState",
        payload: {
          startInterval: true,
          meoTxHash: payload.meoTxHash,
          meoTxHashStatus: 'pending',
          counter: 1,
        },
      });
      const meoTxHashStatus = yield select(({ meo }) => meo.meoTxHashStatus);
      console.log('model meoTxHashStatus ---- ', meoTxHashStatus)
      if (meoTxHashStatus !== 'init' && meoTxHashStatus !== 'pending') {
        console.log('meoTxHashStatus success return ---- ')
        return;
      }
      while (true) {
        const meoTxHash = yield select(({ meo }) => meo.meoTxHash);
        const counter = yield select(({ meo }) => meo.counter);
        // 跳出循环
        if (!meoTxHash || meoTxHash === '') {
          break;
        }
        const meoIdoItem = yield getMeoTxHashDetail(meoTxHash)
        console.log('meoIdoItem: ', meoIdoItem)
        if (!meoIdoItem || meoIdoItem.meoStatus !== 'success') {
          yield put({
            type: "setState",
            payload: {
              startInterval: true,
              meoTxHashStatus: 'pending',
              counter: counter + 1,
              // transferStatus: "init",
            },
          });
          yield call(sleep, 4000);
        } else {
          yield put({
            type: "setState",
            payload: {
              startInterval: true,
              meoTxHashStatus: 'success',
              exchangeCode: meoIdoItem?.exchangeCode,
              meoAmount: meoIdoItem?.meoAmount,
              // transferStatus: "init",
            },
          });
        }
      }
    },

    *stateClear({ payload }, { call, put }) {
      console.log('stateClear');
      yield put({
        type: "setState",
        payload: {
          meoTxHash: '',
          meoTxHashStatus: 'init',
          startInterval: false,
          counter: 1,
        },
      });
    },

  },

  reducers: {
    setState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  }
}

export default MeoModel;