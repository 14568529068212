import NFT721Bridge from "./abi/NFT721Bridge.json";
import TokauMetacityLand from "./abi/TokauMetacityLand.json";
import Web3 from 'web3';

const envFlag = process.env.NODE_ENV === 'development';
const BRIDGE_ABI = NFT721Bridge['abi'];
const METACITY_METACITYLAND_ABI = TokauMetacityLand["abi"];

const NET_URL_TEST = "https://data-seed-prebsc-1-s1.binance.org:8545";
const BSC_CONTRACT_ADDRESS = envFlag
  ? '0xA09e0ed96aC148A7685b48AfAB90bE3231D1b6c0'  // 测试
  : '0xe6b8AB0Abf5C1BE6BC943063773f68c5a4eb8370'; // 正式
const ETH_CONTRACT_ADDRESS = envFlag
  ? '0x97f6653514E9c418AE5e27D9dbdB84253359BF5d'
  : '0xd03f17875750D68dE66bbf1dc6A07573D6B3e7b6';
const LAND_CONTRACT_ADDRESS_BSC = envFlag
  ? '0xD44F80577489e81aFD7013e86172fF4646ea2CD2'
  : '0xEcC89FA4DFaB7D82aD91A2678B7647ce593ed0D2';
const LAND_CONTRACT_ADDRESS_ETH = envFlag
  ? '0x3cF9545A8999AAf1412FD4CF2cc73605Df3902fB'
  : '0xE11fbe40094e42C41109e4Ec18375c68641a301D';

const NET_URL_PROD = "https://bsc-dataseed.binance.org";

const NETWORK_HASH_MAP = {
  'BSC_test': 'https://testnet.bscscan.com/',
  'BSC_prod': 'https://bscscan.com/',
  'ETH_test': 'https://rinkeby.etherscan.io/',
  'ETH_prod': 'https://etherscan.io/',
}

const NETWORK_CHAIN_MAP = {
  'BSC_test': {
    chainId: Web3.utils.toHex(97),
    chainName: 'BSC TESTNET',
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
    }
  },
  'BSC_prod': {
    chainId: Web3.utils.toHex(56),
    chainName: 'BSC MAINNET',
    rpcUrls: ['https://bsc-dataseed1.binance.org/'],
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
    }
  },
  'ETH_test': {
    chainId: Web3.utils.toHex(4),
    chainName: 'Rinkeby Test Network',
    rpcUrls: ['https://rinkeby.infura.io/v3/2b4d7823973942d0b4e6731db28068f5'],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
    }
  },
  'ETH_prod': {
    chainId: Web3.utils.toHex(1),
    chainName: 'Ethereum Mainnet',
    rpcUrls: ['https://mainnet.infura.io/v3/2b4d7823973942d0b4e6731db28068f5'],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
    }
  },
};

const CONTRACT_ADDRESS_MAP = {
  "rinkeby": {
    "NFT721Bridge": {
      "address": "0x97f6653514E9c418AE5e27D9dbdB84253359BF5d",
      "owner": "0x4eb38bC24370c4F796bfa7C68c1781F20b683b3B"
    },
    "TokauMetacityLand": {
      "address": "0x3cF9545A8999AAf1412FD4CF2cc73605Df3902fB",
      "owner": "0x4eb38bC24370c4F796bfa7C68c1781F20b683b3B"
    }
  },
  "bscTestNet": {
    "NFT721Bridge": {
      "address": "0xA09e0ed96aC148A7685b48AfAB90bE3231D1b6c0",
      "owner": "0x4eb38bC24370c4F796bfa7C68c1781F20b683b3B"
    },
    "TokauMetacityLand": {
      "address": "0xD44F80577489e81aFD7013e86172fF4646ea2CD2",
      "owner": ""
    }
  },
  "bscMainNet": {
    "NFT721Bridge": {
      "address": "0xe6b8AB0Abf5C1BE6BC943063773f68c5a4eb8370",
      "owner": "0x4eb38bC24370c4F796bfa7C68c1781F20b683b3B"
    },
    "TokauMetacityLand": {
      "address": "0xEcC89FA4DFaB7D82aD91A2678B7647ce593ed0D2",
      "owner": ""
    }
  },
  "ethereum": {
    "NFT721Bridge": {
      "address": "0xd03f17875750D68dE66bbf1dc6A07573D6B3e7b6",
      "owner": "0x4eb38bC24370c4F796bfa7C68c1781F20b683b3B"
    },
    "TokauMetacityLand": {
      "address": "0xE11fbe40094e42C41109e4Ec18375c68641a301D",
      "owner": "0x4eb38bC24370c4F796bfa7C68c1781F20b683b3B"
    }
  }
}
//final 
export const NET_URL = process.env.NODE_ENV === 'development' ? NET_URL_TEST : NET_URL_PROD

export async function isUserApprove(userAddress) {
  try {
    const { CONTRACT_ADDRESS, LAND_CONTRACT_ADDRESS } = await getChainContract();
    const web3 = new Web3(window.ethereum || NET_URL);
    const Land = new web3.eth.Contract(METACITY_METACITYLAND_ABI, LAND_CONTRACT_ADDRESS);
    console.log('CONTRACT_ADDRESS', CONTRACT_ADDRESS);
    const isApprove = await Land.methods.isApprovedForAll(userAddress, CONTRACT_ADDRESS).call({ from: userAddress });
    return isApprove
  } catch (error) {
    console.log('isUserApprove', error);
  }
}
export async function isOwnerOfTokenId(userAddress, tokenId) {
  try {
    const { LAND_CONTRACT_ADDRESS } = await getChainContract();
    const web3 = new Web3(window.ethereum || NET_URL);
    const Land = new web3.eth.Contract(METACITY_METACITYLAND_ABI, LAND_CONTRACT_ADDRESS);
    console.log('CONTRACT_ADDRESS', LAND_CONTRACT_ADDRESS);
    console.log('tokenId', tokenId);
    const owner = await Land.methods.ownerOf(Web3.utils.toHex(tokenId)).call({ from: userAddress });
    console.log('owner', owner);
    return owner.toUpperCase() === userAddress.toUpperCase();
  } catch (error) {
    console.log('isOwnerOfTokenId', error);
    if (error?.message?.toString()?.includes('owner query for nonexistent token')) {
      return { message: 'nonexistent token' };
    }
    return { message: 'error' };
  }
}

export async function setUserApprove(userAddress) {
  try {
    const { CONTRACT_ADDRESS, LAND_CONTRACT_ADDRESS } = await getChainContract();

    const web3 = new Web3(window.ethereum || NET_URL);
    const Land = new web3.eth.Contract(METACITY_METACITYLAND_ABI, LAND_CONTRACT_ADDRESS);
    const chainId = await web3.eth.getChainId();
    const gasPrice = await web3.eth.getGasPrice();
    console.log('CONTRACT_ADDRESS', CONTRACT_ADDRESS);
    const myData = Land.methods.setApprovalForAll(CONTRACT_ADDRESS, true).encodeABI();
    const nonce = await web3.eth.getTransactionCount(userAddress, "pending");
    const unsigned = {
      from: userAddress,
      to: LAND_CONTRACT_ADDRESS,
      value: "0x00",
      gasPrice: Web3.utils.toHex(parseInt(gasPrice * (chainId === 1 ? 1.2 : 1))),
      nonce: Web3.utils.toHex(nonce),
      chainId,
      data: myData,
    };
    const gas = "0xf4240"; //100000
    unsigned.gas = gas
    return unsigned
  } catch (error) {
    console.log('userApprove', error);
  }
}

export async function crossChainByBridge(userAddress, tokenId) {
  try {
    const web3 = new Web3(window.ethereum || NET_URL);
    const { CONTRACT_ADDRESS, LAND_CONTRACT_ADDRESS } = await getChainContract();
    const bridge = new web3.eth.Contract(BRIDGE_ABI, CONTRACT_ADDRESS);
    const gasFee = await bridge.methods.estimateFee(LAND_CONTRACT_ADDRESS).call({ from: userAddress });
    console.log(gasFee, 'gasFee');
    const attachData = await bridge.methods.attachNFT721(LAND_CONTRACT_ADDRESS, tokenId).encodeABI();
    const chainId = await web3.eth.getChainId();
    const gasPrice = await web3.eth.getGasPrice();
    const nonce = await web3.eth.getTransactionCount(userAddress, "pending");

    const unsigned = {
      from: userAddress,
      to: CONTRACT_ADDRESS,
      value: Web3.utils.toHex(gasFee),
      gasPrice: Web3.utils.toHex(parseInt(gasPrice * (chainId === 1 ? 1.2 : 1))),
      nonce: Web3.utils.toHex(nonce),
      chainId,
      data: attachData,
    };
    unsigned.gas = "0xf4240";
    return unsigned
  } catch (error) {
    console.log('crossChainByBridge', error);

  }
}

// 切换链
export async function getChainType() {
  const chainId = await window.ethereum.request({ method: 'eth_chainId' });
  if (['0x38', '0x61'].includes(chainId)) {
    return 'BSC';
  } else if (['0x1', '0x4'].includes(chainId)) {
    return 'ETH';
  }
}
// 获取查询交易hash的链接
export async function getHashUrl() {
  const chainId = await window.ethereum.request({ method: 'eth_chainId' });
  if (['0x38', '0x61'].includes(chainId)) {
    return envFlag ? NETWORK_HASH_MAP['BSC_test'] : NETWORK_HASH_MAP['BSC_prod'];
  } else if (['0x1', '0x4'].includes(chainId)) {
    return envFlag ? NETWORK_HASH_MAP['ETH_test'] : NETWORK_HASH_MAP['ETH_prod'];
  }
}
// 获取当前链合约地址
export async function getChainContract() {
  let CONTRACT_ADDRESS = '';
  let LAND_CONTRACT_ADDRESS = '';

  const chainId = await window.ethereum.request({ method: 'eth_chainId' });
  if (['0x38', '0x61'].includes(chainId)) {
    CONTRACT_ADDRESS = BSC_CONTRACT_ADDRESS
    LAND_CONTRACT_ADDRESS = LAND_CONTRACT_ADDRESS_BSC;
  } else if (['0x1', '0x4'].includes(chainId)) {
    CONTRACT_ADDRESS = ETH_CONTRACT_ADDRESS
    LAND_CONTRACT_ADDRESS = LAND_CONTRACT_ADDRESS_ETH;
  }
  return { CONTRACT_ADDRESS, LAND_CONTRACT_ADDRESS }
}

// 切换链
export async function switchChain(type) {
  const network = envFlag ? `${type}_test` : `${type}_prod`;
  const web3 = new Web3(window.ethereum || NET_URL);
  console.log('web3',web3);

  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: NETWORK_CHAIN_MAP[network].chainId }],
    });
    return true;
  } catch (switchError) {
    if (switchError.code === 4902) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [NETWORK_CHAIN_MAP[network]],
        });
        return true;
      } catch (addError) {
        console.log('addError', addError);
        return false;
      }
    }
    console.log('switchError', switchError);
    return false;
  }
}
