import { USDT_CONTRACT_ADDRESS } from "../../contract/Tokau";
import Payment from "./abi/Payment.json";
import { TOKAU_ABI } from "./ore2";
import { getLandInfoFromId } from "@/util/TokenIdUtils";
var Web3 = require("web3");
var BN = Web3.utils.BN;

const BUY_LAND_ABI = Payment["abi"]

//test
const NET_URL_TEST = "https://data-seed-prebsc-1-s1.binance.org:8545"
const BUY_LAND_CONTRACT_ADDRESS_TEST = "0xa8f7254FB2E04535cb27d7602204219fB307466D"

//prod
const NET_URL_PROD = "https://bsc-dataseed.binance.org"
const BUY_LAND_CONTRACT_ADDRESS_PROD = "0x62c3728907989Dd9D4fee2eA0929EEd6212656b8"

//final
const NET_URL = process.env.NODE_ENV === 'development' ? NET_URL_TEST : NET_URL_PROD
const BUY_LAND_CONTRACT_ADDRESS = process.env.NODE_ENV === 'development' ? BUY_LAND_CONTRACT_ADDRESS_TEST : BUY_LAND_CONTRACT_ADDRESS_PROD

//get buy land data
export async function getBuyLandAmounts(tokenIds) {

  const web3 = new Web3(window.ethereum || NET_URL);
  let BuyLand = new web3.eth.Contract(BUY_LAND_ABI, BUY_LAND_CONTRACT_ADDRESS);
  // console.log('query tokenIds: ', tokenIds)
  const amounts = await BuyLand.methods.queryBatch(tokenIds).call();
  // console.log('query tokenIds amounts: ', amounts)
  return amounts
}
export async function getBuyLandAmounts2(tokenIds) {
  tokenIds = tokenIds.filter(item => item != undefined)
  if (!tokenIds || tokenIds.length < 1) {
    return [0, 0]
  }
  const web3 = new Web3(window.ethereum || NET_URL);
  let BuyLand = new web3.eth.Contract(BUY_LAND_ABI, BUY_LAND_CONTRACT_ADDRESS);
  // console.log('query tokenIds: ', tokenIds)
  const amounts = await BuyLand.methods.queryBatch(tokenIds).call();
  // console.log('query tokenIds amounts: ', amounts)
  const amountsBNB = await BuyLand.methods.usdtToBnbWei(amounts).call();
  return [web3.utils.fromWei(amounts), web3.utils.fromWei(amountsBNB)]
}
export async function getBuyLandPrice(city, rarity, bias) {
  const web3 = new Web3(window.ethereum || NET_URL);
  let BuyLand = new web3.eth.Contract(BUY_LAND_ABI, BUY_LAND_CONTRACT_ADDRESS);
  let usdt = await BuyLand.methods.getPrice(city, rarity, bias).call()
  let bnb = await getBnbByUsdt(usdt);
  let result = { "usdt": Web3.utils.fromWei(usdt), "bnb": Web3.utils.fromWei(bnb) }
  return result
}
// '1' 'MetaTokyo';
// '0' 'MetaDubai';
// '2' 'MetaHongKong';
// '3' 'TOKAU Homeland';
// ‘4’  ‘Meta2077’;
// '0'  'Basic Land';
// '1'  'Premium Land';
// '2'  'Super Land';
export async function getLandSupply(val, val1) {
  const web3 = new Web3(window.ethereum || NET_URL);
  let BuyLand = new web3.eth.Contract(BUY_LAND_ABI, BUY_LAND_CONTRACT_ADDRESS);
  let LandSupply = await BuyLand.methods.getLandSupply(val, val1).call()
  let LandTotalSupply = await BuyLand.methods.getLandTotalSupply(val, val1).call()
  console.log(LandSupply);
  console.log(LandTotalSupply);
  return { "LandSupply": LandSupply, "LandTotalSupply": LandTotalSupply }
}
export async function getBnbByUsdt(usdt) {
  const web3 = new Web3(window.ethereum || NET_URL);
  let BuyLand = new web3.eth.Contract(BUY_LAND_ABI, BUY_LAND_CONTRACT_ADDRESS);
  let bnbp = await BuyLand.methods.getBNBPrice().call();
  bnbp = Number(bnbp);
  return (parseInt(usdt / bnbp * Math.pow(10, 8))).toString();
}
export async function getPayAmountToContractData(account, tokenIds, priceInfo) {
  const web3 = new Web3(window.ethereum || NET_URL);
  let BuyLand = new web3.eth.Contract(BUY_LAND_ABI, BUY_LAND_CONTRACT_ADDRESS);
  // console.log('query tokenIds amounts: ', priceInfo.amount)
  let chainId = await web3.eth.getChainId();
  let gasPrice = await web3.eth.getGasPrice();
  let value = '0x00'
  if (priceInfo.payToken === 'BNB') {
    // let tmpValue = Web3.utils.toWei(priceInfo.amount)
    // if (new BN(tmpValue).cmp(new BN(Web3.utils.toWei('0.1'))) < 0) {
    //   tmpValue = new BN(tmpValue).add(new BN(Web3.utils.toWei('0.002')))
    // }
    // value = Web3.utils.toHex(tmpValue)
    // console.log('priceInfo', priceInfo)
    if (priceInfo && priceInfo?.amountOb?.BNB) {
      // console.log('select price from priceInfo')
      value = Web3.utils.toWei(priceInfo?.amountOb?.BNB + "")
    } else {
      value = await BuyLand.methods.queryBatch(tokenIds).call();
      value = await getBnbByUsdt(value);
    }
    //value + 3%
    console.log('add 3% prev: ', value)
    value = new BN(value).add(new BN(value).mul(new BN("3")).div(new BN("100")))
    value = value.toString()
    // console.log('final value: ', value)
    value = Web3.utils.toHex(value)
    // value = Web3.utils.toHex(Web3.utils.toWei(priceInfo?.amount))
    // console.log('final pay bnb amount: ', value)

  }
  // const myData = await BuyLand.methods.purchase(tokenIds).send({ from: priceInfo.address, value: value });
  const myData = await BuyLand.methods.purchase(tokenIds).encodeABI();
  var nonce = await web3.eth.getTransactionCount(account, "pending");
  const unsigned = {
    from: account,
    to: BUY_LAND_CONTRACT_ADDRESS,
    value: value,
    gasPrice: Web3.utils.toHex(gasPrice),
    nonce: Web3.utils.toHex(nonce),
    chainId,
    data: myData,
  };
  // let gas = await web3.eth.estimateGas(unsigned);
  unsigned.gas = '0xf4240';
  // console.log("unsigned: ", unsigned)
  return unsigned
}

//check allowance, user approve usdt contract 's amount
export async function getAllowanceUSDT(account) {
  if (!account) {
    return '';
  }
  const web3 = new Web3(window.ethereum || NET_URL);
  let USDTContract = new web3.eth.Contract(TOKAU_ABI, USDT_CONTRACT_ADDRESS);
  let allowance = await USDTContract.methods.allowance(account, BUY_LAND_CONTRACT_ADDRESS).call({ from: account })
  return allowance.toString()
}

//get user approve usdt data
export async function getUserApproveUSDData(account) {
  const web3 = new Web3(window.ethereum || NET_URL);
  let USDTContract = new web3.eth.Contract(TOKAU_ABI, USDT_CONTRACT_ADDRESS);
  let chainId = await web3.eth.getChainId();
  let gasPrice = await web3.eth.getGasPrice();
  let amount = new BN("1000000000000").mul(new BN('1000000000000000000'));
  const myData = USDTContract.methods.approve(BUY_LAND_CONTRACT_ADDRESS, amount).encodeABI();
  // console.log(myData)
  var nonce = await web3.eth.getTransactionCount(account, "pending");
  let unsigned = {
    from: account,
    to: USDT_CONTRACT_ADDRESS,
    value: "0x00",
    gasPrice: Web3.utils.toHex(gasPrice),
    nonce: Web3.utils.toHex(nonce),
    chainId,
    data: myData,
  };
  let gas = "0xc350"; //50000
  unsigned.gas = gas
  return unsigned
}