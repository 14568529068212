/*
 * @description: cpr
 */
import "./common.sass";
// import "./assets/css/common.less";
import { useEffect, Suspense, lazy } from "react";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./App.css";
import 'antd-mobile-v2/dist/antd-mobile.css';
import store from "./dva";
import { Provider } from "react-redux";
import "antd/dist/antd.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactGa from "react-ga4"
const Video = lazy(() => import("./pages/video"));
const HomeComponent = lazy(() => import("./pages/home"));
const Map = lazy(() => import("./pages/map"));
const Roadmap = lazy(() => import("./pages/roadmap"));
const MyProfile = lazy(() => import("./pages/myprofile"));
const Celebrities = lazy(() => import("./pages/celebrities"));
const NftPic = lazy(() => import("./pages/nftPic"));
const Ore = lazy(() => import("./pages/ore"));
const Swap = lazy(() => import("./pages/swap"));
const Metaoremeo = lazy(() => import("./pages/metaoremeo"));
const Meo = lazy(() => import("./pages/meo"));

function divLoading() {
  return (
    <div className="app-wrapper">
      <div className="loading-box">
        <div className="loading"></div>
        <p>loading</p>
      </div>
    </div>
  );
}

function Laoding(Component) {
  return (props) => (
    <Suspense fallback={divLoading()}>
      <Component {...props} />
    </Suspense>
  );
}

function App() {
  useEffect(() => {
    if (window?.ethereum) {
      window.ethereum.on('chainChanged', () => {
        window.location.reload();
      });
    }
    ReactGa.initialize("G-PS788XQBB7");
  });
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <>
            <Switch>
              <Route path={"/Video"} component={Laoding(Video)} />
              {/* <Route path={"/mint/:h"} component={Laoding(Map)} /> */}
              <Route path={"/mint"} component={Laoding(Map)} />
              <Route path={"/roadmap"} component={Laoding(Roadmap)} />
              {/* <Route path={"/lanSomerhalder"} component={Laoding(LanSomerhalder)} /> */}
              <Route path={"/celebrities"} component={Laoding(Celebrities)} />
              {/* <Route path={"/celebrities/item/:id"} component={Laoding(Celebrities)} /> */}
              <Route path={"/myprofile"} component={Laoding(MyProfile)} />
              {/* <Route path={"/NftPic"} component={Laoding(NftPic)} /> */}
              {/* <Route path={"/ore"} component={Laoding(Ore)} />
              <Route path={"/swap"} component={Laoding(Swap)} /> */}
              <Route path={"/metaoremeo"} component={Laoding(Metaoremeo)} />
              <Route path={"/meo"} component={Laoding(Meo)} />
              <Route
                path={"/home"}
                component={Laoding(HomeComponent)}
              ></Route>
              <Redirect to={"/Video"}></Redirect>
              {/* <Redirect from={"/map/pr"} to={"/map"}></Redirect> */}

            </Switch>
          </>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
