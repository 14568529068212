import TokenSwapper from "./abi/TokenSwapper.json";
import TLabsToken from "./abi/TLabsToken.json";
import { abi } from "../../contract/Tokau";
import Web3 from 'web3';
import { utils, BigNumber } from 'ethers';

const { BN } = Web3.utils;
const envFlag = process.env.NODE_ENV === 'development';
const TokenSwapper_ABI = TokenSwapper['abi'];
const TLabsToken_ABI = TLabsToken['abi'];
// const TOKAU_ABI = TOKAU['abi'];
const TOKAU_ABI = TLabsToken['abi'];

const NET_URL_TEST = "https://data-seed-prebsc-1-s2.binance.org:8545/"

const NET_URL_PROD = "https://bsc-dataseed.binance.org"

//final 
export const NET_URL = envFlag ? NET_URL_TEST : NET_URL_PROD

const netWorkKey = envFlag ? "bscTestNet" : "bscMainNet";

const All_CONTRACT = {
  "bscTestNet": {
    "TOKAU": {
      "address": "0xd08f0B68400C4DD2e15BC2d55a3b26536C9F6E6f",
      "owner": "0x79c41df97f27485d823ecb89e97b388fc617acff"
    },
    "TLabsToken": {
      "address": "0xA242034d5E0F8e98F92bB331d10fE0b86Acd8d00",
      "owner": "0x4eb38bC24370c4F796bfa7C68c1781F20b683b3B"
    },
    "TokenSwapper": {
      "address": "0xA411bC5100578F99DD7345BCBF2D32a85AFADB25",
      "owner": "0x4eb38bC24370c4F796bfa7C68c1781F20b683b3B"
    }
  },
  "bscMainNet": {
    "TOKAU": {
      "address": "0xc409ec8a33f31437ed753c82eed3c5f16d6d7e22",
      // "owner": "0x79c41df97f27485d823ecb89e97b388fc617acff"
    },
    "TLabsToken": {
      "address": "0x45Fffed8d9651fE9EA0321fcC9b15Ee5e052A208",
      "owner": "0x37827c4198293B89A1df81A35dC2bFf5A6fB224D"
    },
    "TokenSwapper": {
      "address": "0xd7b2a5c11b9B64bEc96aa499b8bA312D9A018373",
      "owner": "0x37827c4198293B89A1df81A35dC2bFf5A6fB224D"
    }
  }
}


export async function setUserApprove(userAddress) {
  console.log('setUserApprove', userAddress);

  try {
    const TokenSwapper_ADDRESS = All_CONTRACT[netWorkKey].TokenSwapper.address;
    // const TLabsToken_ADDRESS = All_CONTRACT[netWorkKey].TLabsToken.address;
    const TOKAU_ADDRESS = All_CONTRACT[netWorkKey].TOKAU.address;

    const web3 = new Web3(window.ethereum || NET_URL);
    const tokau = new web3.eth.Contract(TOKAU_ABI, TOKAU_ADDRESS);
    const chainId = await web3.eth.getChainId();
    const gasPrice = await web3.eth.getGasPrice();
    console.log('tokau', tokau);
    const amount = new BN("1000000000000000").mul(new BN('1000000000000000000'));

    const myData = tokau.methods.approve(TokenSwapper_ADDRESS, amount).encodeABI();
    // const approve = tokau.methods.approve;
    console.log('myData', myData);
    const nonce = await web3.eth.getTransactionCount(userAddress);
    // const nonce = await web3.eth.getTransactionCount(userAddress, "pending");
    const unsigned = {
      from: userAddress,
      to: TOKAU_ADDRESS,
      value: "0x00",
      gasPrice: Web3.utils.toHex(gasPrice),
      nonce: Web3.utils.toHex(nonce),
      chainId,
      data: myData,
    };
    const gas = "0xf4240"; //100000
    unsigned.gas = gas
    return unsigned
  } catch (error) {
    console.log('userApprove', error);
  }
}



export async function getBalanceOf(address) {
  console.log('getBalanceOf', address);

  try {
    const web3 = new Web3(window.ethereum || NET_URL);
    const CONTRACT_ADDRESS2 = All_CONTRACT[netWorkKey].TLabsToken.address;
    const CONTRACT_ADDRESS3 = All_CONTRACT[netWorkKey].TOKAU.address;
    const tLabsToken = new web3.eth.Contract(TLabsToken_ABI, CONTRACT_ADDRESS2);
    const tokau = new web3.eth.Contract(TOKAU_ABI, CONTRACT_ADDRESS3);
    let tbsBlance = await tLabsToken.methods.balanceOf(address).call();
    let tokauBlance = await tokau.methods.balanceOf(address).call();
    tokauBlance = utils.formatUnits(tokauBlance, 18).toString()
    tbsBlance = utils.formatUnits(tbsBlance, 18).toString()
    console.log(tbsBlance, tokauBlance);
    return { tbsBlance, tokauBlance }
  } catch (error) {
    console.log('getBalanceOf', error);
    return { tbsBlance: 0, tokauBlance: 0 };
  }
}


export async function getAllowance(account) {
  const web3 = new Web3(window.ethereum || NET_URL);
  const TOKAU_ADDRESS = All_CONTRACT[netWorkKey].TOKAU.address;
  const TokenSwapper_ADDRESS = All_CONTRACT[netWorkKey].TokenSwapper.address;
  try {
    console.log(1);
    const TOKAU = new web3.eth.Contract(TOKAU_ABI, TOKAU_ADDRESS);
    console.log('getAllowance account', account);
    // const allowance = await TOKAU.methods.allowance(account, TokenSwapper_ADDRESS)
    const allowance = await TOKAU.methods.allowance(account, TokenSwapper_ADDRESS).call({ from: account })
    console.log(3);
    return Number(allowance) > 0
  } catch (error) {
    console.log('getAllowance', error);
    return 0;
  }
}

export async function swapTokauToTBS(account, amount) {
  console.log('swapTokauToTBS account', account);

  try {
    console.log('swapTokauToTBS', 2);
    const web3 = new Web3(window.ethereum || NET_URL);
    console.log('swapTokauToTBS', 3);
    const CONTRACT_ADDRESS = All_CONTRACT[netWorkKey].TokenSwapper.address;
    console.log('swapTokauToTBS', 4);
    const tokenSwapper = new web3.eth.Contract(TokenSwapper_ABI, CONTRACT_ADDRESS);
    console.log('swapTokauToTBS', 5);
    const tokauAmount = utils.parseUnits(amount.toString(), 18).toString()
    console.log('tokauAmount', tokauAmount);
    const myData = await tokenSwapper.methods.swapToken(tokauAmount).encodeABI();
    console.log('swapTokauToTBS', 6);
    const gasPrice = await web3.eth.getGasPrice();
    console.log('swapTokauToTBS', 7);
    const chainId = await web3.eth.getChainId();
    console.log('swapTokauToTBS', 8);

    // const transactionCount = await web3.eth
    //   .getTransactionCount(account, () => {
    //     console.log('swapTokauToTBS', 10);
    //   })
    //   .then((count) => {
    //     console.log('swapTokauToTBS', 11);

    //     return count;
    //   })
    //   .catch((err) => {
    //     console.log(`\n\n ERROR (transactionCount) : ${err}\n\n`);
    //   });
    // console.log('transactionCount', transactionCount);
    // console.log('swapTokauToTBS', 9);

    const nonce = await web3.eth.getTransactionCount(account, 'pending', (res) => {
      console.log(res);
    });


    // const nonce = await web3.eth.getTransactionCount(account);
    console.log('swapTokauToTBS', 12);
    console.log('nonce', nonce);

    const unsigned = {
      from: account,
      to: CONTRACT_ADDRESS,
      value: '0x00',
      gasPrice: Web3.utils.toHex(gasPrice),
      nonce: Web3.utils.toHex(nonce),
      chainId,
      data: myData,
    };
    unsigned.gas = '0xf4240';
    console.log('tokenSwapper', unsigned);
    return unsigned;
  } catch (error) {
    console.log('swapTokauToTBS', error);
    return {};
  }
}

export async function getTransactionByHash(tx) {
  try {
    const web3 = new Web3(window.ethereum || NET_URL);
    const res = await web3.eth.getTransactionReceipt(tx);
    return res
  } catch (error) {
    console.log('getTransactionByHash', error);
    return {};
  }
}