// import axios from '../service/request';
import axios from 'axios';
import { BASE_URL, CHAIN_ID } from '../service/config';
import { notification, Button } from 'antd';
import { getShowAddress, checkChain } from '../util/common';
import localStorage from "localStorage";
import { Toast } from "antd-mobile";

const LoginModel = {
  namespace: 'login',
  state: {
    username: '',
    login: false,
    loginType: 'metamask',
    showLoginModal: false,
    address: '',
    showAddress: '',
    nickname: '',
    email: '',
    gender: 0,
    userCurrentTradeTokenId: 0,
    h: '1',
    guildId: '1',
    avatar: {},
    wallectConnector: null,
    shareLink: '',
    limitedLandCodes: ['MetaTokyo'],
    businessId: '',
  },
  effects: {
    *login({ payload }, { call, put }) {
      let address = "";
      console.log(1, address);
      if (JSON.stringify(payload) === "{}" || payload === undefined) {
        if (window.ethereum !== undefined) {
          const accounts = yield window.ethereum.request({
            method: "eth_requestAccounts",
          });
          address = accounts[0];
        }
      } else {
        address = payload.address;
      }
      console.log(2, address);

      if (!address) {
        let wcStr = '';
        try {
          wcStr = localStorage.getItem("walletconnect");
        } catch (error) {
          // console.log('local storage get wallet connect info error')
        }
        if (wcStr) {
          let wcObj = JSON.parse(wcStr);
          address = wcObj["accounts"][0];
        }
      }
      if (!address) {
        return // console.log('final address = "" ')
      }
      console.log(3, address);

      // yield put({
      //   type: 'setState',
      //   payload: { ...payload }
      // })
      let userProfileRes = yield call(axios.get, `${BASE_URL}u/${address}/profile`);
      if (!userProfileRes.data?.data?.shareLink) {
        userProfileRes.data.data.shareLink = ""
      }
      console.log('user profile info: ', userProfileRes)
      // alert(JSON.stringify(userProfileRes));
      let userProfile = userProfileRes?.data?.data || {};
      let showAddress = getShowAddress(address);
      console.log('user profile info2: ', userProfile)

      userProfile.showAddress = showAddress;
      checkChain('login')
      // if (window?.ethereum) {
      //   window.ethereum.request({ method: 'eth_chainId' }).then((chainId) => {
      //     if (chainId !== CHAIN_ID && (chainId === '0x4' || chainId === '0x1')) {
      //       notification.warn({
      //         duration: 6,
      //         message: CHAIN_ID === '0x1'
      //           ? intl.get(getIntlKey("pleaseswitchthechaintoethereummainnet."))
      //           : intl.get(getIntlKey("pleaseswitchtorinkebytestnetworkfirst.")),
      //         // description: intl.get(getIntlKey("pleaseswitchtorinkebytestnetworkfirst.")),
      //         btn: <Button className='switch-name' type="primary" onClick={() => { switchChain('ETH') }}>  switch chain </Button>
      //       })
      //     } else {
      //       // 显示链接成功
      //       showConnected();
      //     }
      //   })
      // }
      // checkChain()
      
      yield put({
        type: 'setState',
        payload: { ...payload, address, ...userProfile }
      })
    },
    *logout({ payload }, { call, put }) {
      yield put({
        type: 'setStateClear'
      })
    },
    *setUsernamePassword({ payload }, { call, put }) {
      yield put({
        type: 'setState',
        payload: { ...payload }
      })
    },
    *setUserCurrentTradeTokenId({ payload }, { put }) {
      // console.log('exec setUserCurrentTradeTokenId, payload', payload)
      yield put({
        type: 'setState',
        payload: { ...payload }
      })
    },
    *setUpper({ payload }, { call, put, select }) {
      console.log(' login setUpper')
      if (!payload.address || (!payload.h && !payload.guildId)) {
        return
      }
      let setUpperRes = yield call(axios.post, `${BASE_URL}u/userSetUpper`, payload, {
        headers: {
          'content-type': 'application/json'
        }
      })
      console.log('set upper res: ', setUpperRes)
      if (setUpperRes.code === 0) {
        if (setUpperRes.data?.h) {
          localStorage.removeItem('parentsId');
          Toast.info('Connected')
        }
        yield put({
          type: 'setState',
          payload: { h: setUpperRes.data?.h, guildId: setUpperRes.data?.guildId }
        })
        yield put({
          type: 'login'
        })
      }
      const userProfileRes = yield call(axios.get, `${BASE_URL}u/${payload?.address}/profile`);
      const userProfile = userProfileRes?.data?.data || {};
      const showAddress = getShowAddress(payload?.address);
      userProfile.showAddress = showAddress;
      yield put({
        type: 'setState',
        payload: { ...payload, ...userProfile }
      })
    },

    *showLoginModal({ payload }, { put }) {
      // console.log('show login modal payload: ', payload)
      yield put({
        type: 'setState',
        payload: { showLoginModal: payload.showLoginModal }
      })
    },

    *checkLoginStatus({ payload }, { put }) {
      // console.log('login model check login status')
      if (window.ethereum) {

      } else {

      }
    },
    *setContext({ payload }, { call, put }) {
      yield put({
        type: 'setState',
        payload
      });
    }
  },
  reducers: {
    setState(state, { payload }) {
      return {
        ...state,
        ...payload
      }
    },
    setStateClear(state,) {
      console.log('setStateClear');
      localStorage.removeItem("walletconnect");
      return {
        ...state,
        username: '',
        login: false,
        address: '',
        showAddress: '',
        nickname: '',
        email: '',
        gender: 0,
        userCurrentTradeTokenId: 0,
        showLoginModal: false,
        shareLink: '',
      }
    }
  },

}

export default LoginModel;