import myaxios from './request';

export const getExchangeOrderList = async ({ address = '' , type = 'ore'}) => {
  try {
    const { data = [], code } = await myaxios.get(`/exchangeOrder/getExchangeOrderList`,{
      params: {
        userAddress: address,
        type: type
      }
    })
    if (code === 0) {
      return data
    }
    return []
  } catch (error) {
    console.log('get exchange order list error:', error);
    return []
  }
}

export const addExchangeOrder = async ({fromAmount, toAmount, type, userAddress, txHash}) => {
  try {
    const {data = {}, code} = await myaxios.post(`/exchangeOrder/add`, {fromAmount, toAmount, type, userAddress, txHash});
    return data
  } catch (error) {
    console.log('getMetaOrder error:', error);
    return {}
  }
}

export const updateExchangeStatus = async ({txHash, status = 'pending'}) => {
  try {
    const {data = {}, code} = await myaxios.post(`/exchangeOrder/updateStatus`, {txHash, status});
    return data
  } catch (error) {
    console.log('getMetaOrder error:', error);
    return {}
  }
}