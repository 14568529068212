// const devBaseURL = "https://app-testnet.tokau.io/tp";
//const devBaseURL = "http://192.168.1.15:10006";
// const devBaseURL = 'http://localhost:10006';
// const devBaseURL_OLD = 'https://test-metacity.tokau.io/tp';
// const proBaseURL_OLD = "https://metacity.tokau.io/tp";

// const devBaseURL_F = "https://app-testnet.tokau.io";
const devBaseURL_F = "https://test-metacity.tokau.io";
const proBaseURL_F = "https://metacity.tokau.io";

const devBaseURL_New = 'https://test-metacity.tokau.io/mw/';
// const devBaseURL_New = 'http://localhost:10007/';
const proBaseURL_New = 'https://metacity.tokau.io/mw/';

// console.log("current env: ", process.env.NODE_ENV);
export const BASE_URL =
  process.env.NODE_ENV === "development" ? devBaseURL_New : proBaseURL_New;

export const BASE_URL_F =
  process.env.NODE_ENV === "development" ? devBaseURL_F : proBaseURL_F;

export const CHAIN_ID =
  process.env.NODE_ENV === "development" ? "0x4" : "0x1";

export const TIMEOUT = 30000;
