import axios from "../service/request";
import { getLandInfoFromId, maxRarity } from "@/util/TokenIdUtils"
import { getBuyLandPrice } from "@/util/contract/buyLand";
import { sleep } from "../util/common";
const _ = require('lodash');
const CartModel = {
  namespace: "cart",

  state: {
    cartList: [],
    cartTotalAmount: {}
  },

  effects: {
    *getCartList({ payload }, { call, put, select }) {
      const address = yield select(({ login }) => login.address)
      if (!address) {
        return []
      }
      const cartListRes = yield call(
        axios.get,
        `/cart/${address}/detail`
      );
      if (cartListRes?.code === 0 && cartListRes?.data) {
        const data = cartListRes.data;
        const biasOb = {}
        const totalAmountOb = {}
        const ob = {}
        yield _.forEach(data?.carts, async (cart) => {
          const priceList = [];
          if (cart.tokenId) {
            let info = getLandInfoFromId(Number(cart.tokenId))
            console.log(info, 'info');
            if (info?.length) {
              if (!biasOb[info?.[0] * maxRarity + info?.[3]]) {
                biasOb[info?.[0] * maxRarity + info?.[3]] = 0
              }
              let price = await getBuyLandPrice(info?.[0], info?.[3], biasOb[info?.[0] * maxRarity + info?.[3]])
              console.log(price, 'price');
              Object.keys(price).forEach((key, index) => {
                if (
                  !totalAmountOb[cart.landCode] ||
                  !totalAmountOb[cart.landCode][key.toLowerCase()]
                ) {
                  ob[key.toLowerCase()] = parseFloat(price[key]);
                  totalAmountOb[cart.landCode] = ob;
                } else {
                  totalAmountOb[cart.landCode][key.toLowerCase()] +=
                    parseFloat(price[key]);
                }
                if (key) {
                  priceList.push({ amount: Number(price?.[key]).toFixed(3) || '', payToken: key.toUpperCase() })
                }
              })
              biasOb[info?.[0] * maxRarity + info?.[3]] += 1;
              cart.prices = priceList;
            }
          }
        });
        console.log(totalAmountOb);
        data["totalAmount"] = totalAmountOb
        yield put({
          type: "setState",
          payload: {
            cartList: data?.carts || [],
            cartTotalAmount: data.totalAmount,
          },
        });
      }
    },

    //add cart
    *addCart({ payload }, { call, put, select }) {
      const address = yield select(({ login }) => login.address)
      if (!address) {
        return
      }
      yield call(axios.post, `/cart/${address}/add`, { productId: payload.productId }, {
        headers: {
          'content-type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      })
      yield put({ type: 'getCartList' })
    },

    //delete cart
    *deleteCart({ payload }, { call, put, select }) {
      const address = yield select(({ login }) => login.address)
      if (!address) {
        return
      }
      yield call(axios.post, `/cart/${address}/delete`, payload.cartIds, {
        headers: {
          'content-type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      })
      yield put({ type: 'getCartList' })
    }
  },

  reducers: {
    setState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

  },
};

export default CartModel;
