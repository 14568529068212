import React, { useEffect } from "react";
import { Modal } from "antd-mobile-v2";
import Frame1019 from "@/assets/imgs/Frame1019.png";
import closeModal from "@/assets/imgs/closeModal.png";

import './index.less';

const modalContentMap = {
  'common': <div className="item-title">
    <div className="item-title-icon">
      <img
        className={""}
        src={Frame1019}
        alt=""
      />
    </div>
    <div className="item-title-right">
      <div className="item-title-right-bottom">
        Land NFTの商品詳細を確認した上、注文してください。
      </div>
    </div>
    <div className="two-modal-button" >
      <div className="my-modal-button">注文する</div>
      <div className="my-modal-button">友達を招待する</div>
    </div>
  </div>,
}

const MyModal = ({ title, modalContent, contentType, className = 'my-modal-container', ...props }) => {
  return (
    <Modal
      className={className}
      {...props}
    >
      <div className="my-modal-content">
        <div className="modal-title">{title}</div>
        {contentType ? modalContentMap?.[contentType] : modalContent}
      </div>
    </Modal>
  )
}

export default MyModal;
