import React from 'react'
import './index.sass'
import metahomeBtnBg from '../../asset/imgs/metahomeBtnBg.svg'

// src = {},
const MetaButton = ({ className = '', text = '', style = {}, onClick = () => { }, src = metahomeBtnBg }) => {
  return (
    <div className={`metacity-btn ${className}`} style={style} onClick={() => onClick()}>
      <img alt='' src={src} className='meta-btn-bg' />
      <div className='btn-name'>{text}</div>
    </div>
  )
}

export default MetaButton;
