import "./index.sass";
import Header from "../../components/NewHeader";
import Footer from "../../components/Footer";
import meobanner from "../../asset/imgs/meo/meobanner.png";
import mbmeobanner from "../../asset/imgs/meo/mbmeobanner.png";
import Exchange from "../../asset/imgs/meo/Exchange.png";
import Info from "../../asset/imgs/meo/Info.png";
import USDT from "../../asset/imgs/meo/USDT.png";
import ETH from "../../asset/imgs/meo/ETH.png";
import BNB from "../../asset/imgs/meo/BNB.png";
import TBS from "../../asset/imgs/meo/TBS.png";
import TRON from "../../asset/imgs/meo/TRON.png";
import backIcon from "../../asset/imgs/meo/backIcon.png";
import CancleICON from "../../asset/imgs/meo/CancleICON.png";
import emileLogo from "../../asset/imgs/meo/emileLogo.png";
import meologo from "../../asset/imgs/meo/meologo.png";
import Success from "../../asset/imgs/meo/Success.png";
import mbSuccess from "../../asset/imgs/meo/mbSuccess.png";
import MetaButton from "../../components/MetaButton";
import CarretDown from "../../asset/imgs/meo/CarretDown.png";
import processingIcon from "../../asset/imgs/swap/processingIcon.png";
import { Input, Spin, notification, Button, Drawer, Radio, Space } from "antd";
import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getTransactionByHash } from "../../util/contract/swap";
import { sendTx } from "../../util/contract/contractCommon";
import intl from "react-intl-universal";
import { getIntlKey } from "@/util/language";
import { add, debounce } from "lodash";
import Countdown from "react-countdown";
import tokaulogo from "../../asset/imgs/swap/tokaulogo.png";
import boxDetailBg from '../../asset/imgs/map/boxDetailBg.png'
import { addExchangeOrder, getExchangeOrderList, updateExchangeStatus } from "../../service/exchangeOrderService";
import { useMemo } from "react";
import { switchChain } from "../../util/contract/bridge";
import { getETHOrBSCTransferParams, getMEOPrice, getTBSPrice, getTokenPriceByTokenCode, hasTronEnv, idoGetBalanceOfUser, tronTransferErc20 } from "../../service/idoService";
import usePrevious from "../../util/hooks/usePrevious.js";
import Web3 from 'web3';
import { compareStrUseBN } from "../../util/common";
import { useHistory } from "react-router-dom";

const chainUrl = process.env.NODE_ENV === 'development' ? 'https://testnet.bscscan.com' : 'https://bscscan.com';

const getChainUrlByToken = (chainCode, txHash) => {
  let tmpEnv = process.env.NODE_ENV;
  if (tmpEnv === 'development') {
    if (chainCode === 'BSC') {
      return `https://testnet.bscscan.com/tx/${txHash}`
    } else if (chainCode === 'ETH') {
      return `https://rinkeby.etherscan.io/tx/${txHash}`;
    }
  } else {
    if (chainCode === 'BSC') {
      return `https://bscscan.com/tx/${txHash}`
    } else if (chainCode === 'ETH') {
      return `https://etherscan.io/tx/${txHash}`;
    }
  }

}

const debounceFunc = debounce((func) => {
  func();
}, 4000);


const mapStateToProps = ({ login, webstyle, meo }) => {
  return {
    address: login.address,
    clientWidth: webstyle.clientWidth,
    loginType: login.loginType,
    chain: meo.chain,
    tokenCode: meo.tokenCode,
    orderList: meo.orderList,
    meoTxHashStatus: meo.meoTxHashStatus,
    meoTxHash: meo.meoTxHash,
    startInterval: meo.startInterval,
    counter: meo.counter,
    exchangeCode: meo.exchangeCode,
    meoAmount: meo.meoAmount,
  };
};
const connector = connect(mapStateToProps);

const { clientWidth } = document.body;

let nkey = '';
const MeoPage = ({ address, clientWidth, loginType, chain, tokenCode, orderList, meoTxHashStatus, meoTxHash, startInterval, counter, dispatch, exchangeCode, meoAmount }) => {

  const history = useHistory();
  const [spinning, setSpinning] = useState(false);
  const [showViewProfile, setShowViewProfile] = useState(false);
  const [pending, setPending] = useState(false);
  const [tokenAmount, setTokenAmount] = useState(0);
  const [tronEthAddress, setTronEthAddress] = useState('');
  const [balance, setBalance] = useState('0');
  const [ethPrice, setEthPrice] = useState('0');
  const [bnbPrice, setBnbPrice] = useState('0');
  const [tbsPrice, setTbsPrice] = useState('0');
  const [meoPrice, setMeoPrice] = useState('0.002625');
  const [announcementFlag, setAnnouncementFlag] = useState(true)
  const [announceEmile, setAnnounceEmile] = useState(true)
  const [tokenPriceMap, setTokenPriceMap] = useState({
    USDT: '1',
    ETH: '0',
    BNB: '0'
  })

  useEffect(() => {
    dispatch({
      type: 'meo/stateClear',
      payload: {}
    })
  }, [])


  useEffect(() => {
    // idoGetBalanceOfUser('BSC', 'USDT', address)
    loadTokenPriceMap();
    dispatch({
      type: 'meo/getIdoList',
      payload: {
        userAddress: address,
      }
    })
    if (meoTxHashStatus === 'success') {
      notification.success({ message: intl.get(getIntlKey("successed!")) })
      dispatch({
        type: 'meo/stateClear',
        payload: {}
      })
    }
    // clear state

  }, [address])

  useEffect(() => {
    console.log(`meoTxHashStatus: ${meoTxHashStatus}, meoTxHash: ${meoTxHash}`)
    // 
    if (meoTxHashStatus === 'success') {
      dispatch({
        type: 'meo/getIdoList',
        payload: {
          userAddress: address,
        }
      })
      dispatch({
        type: 'meo/stateClear'
      })

      debounceFunc(() => {
        notification.success({
          message: intl.get(getIntlKey("successed!")),
          description: intl.get(
            getIntlKey("transactionissuccessed.")
          ),
        });
        notification.close(nkey)
        setShowViewProfile(true)
      })

    }

  }, [meoTxHashStatus, meoTxHash, counter])

  // useEffect(() => {
  //   if (!startInterval) {
  //     if (meoTxHash != '' && ) {

  //     }
  //   }
  // }, [])

  const loadTokenPriceMap = async () => {
    let res1 = await getTokenPriceByTokenCode('ETH');
    if (res1.status == 200) {
      setEthPrice(res1.data?.price);
    };

    let res2 = await getTokenPriceByTokenCode('BNB');
    if (res2.status == 200) {
      setBnbPrice(res2.data?.price)
    }

    let res3 = await getTBSPrice();
    if (res3.status == 200) {
      setTbsPrice(res3.data?.data)
    }

    let res4 = await getMEOPrice();
    if (res4.status == 200) {
      setMeoPrice(res4.data?.data)
    }
  }

  const selectToken = async (e) => {
    console.log('select token: ', e.target.value)
    const res = await dispatch({
      type: "meo/selectChain",
      payload: {
        chain,
        tokenCode: e.target.value,
      },
    });
    console.log('selectToken', res);
  }

  const selectChain = (e) => {
    let chainCode = e.target.value;
    dispatch({
      type: "meo/selectChain",
      payload: {
        chain: chainCode,
      },
    });

  }

  const idoTransferETHBSC = async () => {
    try {

      let params = await getETHOrBSCTransferParams(chain, tokenCode, tokenAmount, address);
      console.log('eth bsc transfer params: ', params)
      nkey = new Date().getTime()
      notification.success({
        key: nkey,
        message: intl.get(getIntlKey("processing...")),
        description: intl.get(
          getIntlKey("transactionisprocessing,pleasewaitpatiently.")
        ),
        icon: (
          <span className="process-icon">
            <img alt="" src={processingIcon} />
          </span>
        ),
        duration: 1000,
      });
      let txHash = await sendTx(loginType, params)
      if (txHash) {
        dispatch({
          type: 'meo/startQueryMeo',
          payload: {
            meoTxHash: txHash,
          }
        })
      }
    } catch (error) {
      notification.close(nkey);
    }
  }

  const getCurrentChainId = async () => {
    let chainId = 'none';
    if (window.ethereum) {
      chainId = await window.ethereum.request({ method: 'eth_chainId' });
    }
    return chainId;
  }

  const checkChainIsRight = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let chainId = await getCurrentChainId();
        console.log('function checkChainIsRight chainId: ', chainId)
        let rightChainFlag = true;
        if (chain === 'ETH' && (chainId !== '0x1' && chainId !== '0x4')) {
          await switchChain('ETH');
          rightChainFlag = false;
        } else if (chain === 'BSC' && (chainId !== '0x38' && chainId !== '0x61')) {
          await switchChain('BSC');
          rightChainFlag = false;
        }
        if (rightChainFlag) {
          resolve('success')
        } else {
          reject('fail')
        }
      } catch (error) {
        console.log('function checkChainIsRight error: ', error)
        reject('fail')
      }

    })
  }

  const hasEnoughBalance = async () => {
    console.log(balance, tokenAmount);
    let cmpR = await compareStrUseBN(balance, tokenAmount);
    if (cmpR >= 0) {
      return true;
    }
    return false;
  }

  const confirmIdo = async () => {
    console.log('click confirmIdo')
    if (!tokenAmount) {
      return notification.warn({ message: 'Please enter the token amount' })
    }
    let enoughFlag = await hasEnoughBalance();
    if (!enoughFlag) {
      return notification.warn({ message: intl.get(getIntlKey("yourbalanceisnotenough.")) })
    }
    if (meoTxHashStatus === 'pending') {
      return
    }
    if (chain === 'ETH' || chain === 'BSC') {
      try {
        //
        let rightChainFlag = await checkChainIsRight();
        console.log('right chain flag: ', rightChainFlag)
        if (rightChainFlag === 'fail') {
          console.log('plz select right chain')
        } else {
          console.log('click ido transfer')
          setPending(true)
          await idoTransferETHBSC()
        }
      } catch (error) {
        console.log('confirmIdo error', error)
        setPending(false)
      }
    } else if (chain === 'TRON') {

      let tronEnv = hasTronEnv();
      console.log(`click tron transfer, has tronEnv: ${tronEnv}`)
      if (!tronEnv) {
        notification.error({ message: 'install tronLink' })
      } else {
        if (!tronEthAddress || tronEthAddress === '') {
          return notification.error({ message: intl.get(getIntlKey("PlzEnterETHwalletAddress")) })
        }
        let tmpEnv = process.env.NODE_ENV;
        if (tmpEnv === 'development' && chain === 'TRON' && tokenCode === 'ETH') {
          return notification.error({ message: 'test env tron eth invalid ' })
        }
        try {
          setPending(true)
          let txHash = await tronTransferErc20(tokenAmount, tokenCode, tronEthAddress)
          console.log('tron txHash: ', txHash)
          if (txHash) {
            dispatch({
              type: 'meo/startQueryMeo',
              payload: {
                meoTxHash: txHash,
              }
            })
          }
        } catch (error) {

        } finally {
          setPending(false)
        }

      }
    }
    setPending(false)
  }

  const renderTokenByChain = useMemo(() => {
    return <Radio.Group buttonStyle="solid" onChange={selectToken} value={tokenCode} >
      {chain === 'BSC'
        ? <>
          <Radio.Button value={'BNB'}> <img alt="" src={BNB}></img>BNB</Radio.Button>
          <Radio.Button value={'TBS'}><img alt="" src={TBS}></img>TBS</Radio.Button>
        </>
        : <Radio.Button value={'ETH'}> <img alt="" src={ETH}></img>ETH</Radio.Button>
      }
      <Radio.Button value={'USDT'}> <img alt="" src={USDT}></img>USDT</Radio.Button>
    </Radio.Group>
  }, [chain, tokenCode])

  const tokenAmountToUSDT = useMemo(() => {
    let r = 0;
    let tmpTokenPrice = '1';
    if (tokenCode === 'ETH') {
      tmpTokenPrice = ethPrice;
    } else if (tokenCode === 'BNB') {
      tmpTokenPrice = bnbPrice;
    } else if (tokenCode === 'TBS') {
      tmpTokenPrice = tbsPrice;
    }
    try {
      r = parseFloat(Number(tokenAmount) * Number(tmpTokenPrice)).toFixed(2)
      console.log(`tokenAmount: ${tokenAmount}, price: ${tmpTokenPrice}, value: ${r}`)
      // r = parseFloat(Number(tmpTokenPrice)).toFixed(2)
    } catch (error) {
      console.log('cal balanceUSDT error')
    }
    return r;
  }, [tokenAmount, tokenCode, ethPrice, bnbPrice, tbsPrice])

  const formatHash = (hash) => {
    if (hash && hash.length > 15) {
      return hash.substring(0, 15) + "..."
    }
    return hash
  }

  const receiveMeoAmount = useMemo(() => {
    let r = 0;
    let tmpTokenPrice = '1';
    if (tokenCode === 'ETH') {
      tmpTokenPrice = ethPrice;
    } else if (tokenCode === 'BNB') {
      tmpTokenPrice = bnbPrice;
    } else if (tokenCode === 'TBS') {
      tmpTokenPrice = tbsPrice;
    }
    try {
      r = parseFloat(Number(tokenAmount) * Number(tmpTokenPrice) / meoPrice).toFixed(4)
    } catch (error) {
      console.log('cal receiveMeoAmount error')
    }
    return r;
  }, [ethPrice, bnbPrice, tokenCode, tokenAmount])

  useEffect(() => {
    initData()
  }, [chain, tokenCode, address])

  const initData = async () => {
    if (!(chain && tokenCode && address)) {
      return
    }
    let balance = await idoGetBalanceOfUser(chain, tokenCode, address);
    console.log('balance', balance);
    if (!balance) {
      return
    }
    if (chain === 'ETH' && tokenCode === 'USDT') {
      balance = Web3.utils.fromWei(balance, 'Mwei')
    } else if (chain === 'TRON' && tokenCode === 'USDT') {
      balance = Web3.utils.fromWei(balance, 'Mwei')
    } else {
      balance = Web3.utils.fromWei(balance, 'ether')
    }
    let dotIndex = balance.indexOf('.');
    if (dotIndex !== -1 && balance.length >= 8) {
      balance = balance.substring(0, dotIndex + 7)
    }
    setBalance(balance)
  }
  const orderItem = (order, index) => (
    <div key={order?.tokenId || index}>
      {clientWidth > 750 ? (
        <div
          className={
            order?.tokenId
              ? "map-table-value order-map-table-value"
              : "map-table-value order-map-table-value minting-BG"
          }
          style={{ borderBottom: "1px solid #fff" }}
        >
          <span>{order?.chainCode || ""}</span>
          <span>{order?.token || ""}</span>
          <span>{order?.amount || ""}</span>
          <span>{order?.meoAmount || ""}</span>
          <span>{order?.tokenPrice || ""}</span>
          <span>
            <a
              target="_blank"
              rel="noreferrer"
              href={getChainUrlByToken(order?.chainCode, order?.txHash)}
            >
              {formatHash(order?.txHash)}
            </a>
          </span>
          <span>{new Date(`${order?.createTime} GMT+00:00`).toLocaleString()}</span>
          <span>{order?.meoStatus}</span>
          {/* <span>
            <a
              target="_blank"
              rel="noreferrer"
              href={getChainUrlByToken('ETH', order?.txHash)}
            >
              {formatHash(order?.meoTxHash)}
            </a>
          </span>
          <span>

          </span> */}
        </div>
      ) : (
        <div
          className={
            order?.status === "success"
              ? "map-table-value mb-hash"
              : "map-table-value mb-hash minting-BG"
          }
          key={index}
          style={{ borderBottom: "1px solid #fff" }}
        >
          <div className="Hash-data">
            <div>
              <span>{intl.get(getIntlKey("chain"))}</span>
              <span>{order?.chainCode || ""}</span></div>
            <div>
              <span>{intl.get(getIntlKey("token"))}</span>
              <span>{order?.token || ""}</span></div>
            <div>
              <span>{intl.get(getIntlKey("amount"))}</span>
              <span>{order?.amount || ""}</span></div>
            <div>
              <span>Meo</span>
              <span>{order?.meoAmount || ""}</span></div>
            <div>
              <span>{intl.get(getIntlKey("price"))}</span>
              <span>{order?.tokenPrice || ""}</span></div>
            <div>
              <span>{intl.get(getIntlKey("transactionhash"))}</span>
              <span style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={getChainUrlByToken(order?.chainCode, order?.txHash)}
                  style={{ color: "blue" }}
                >
                  {formatHash(order?.txHash)}
                </a>
              </span></div>
            <div>
              <span>{intl.get(getIntlKey("date"))}</span>
              <span>{new Date(`${order?.createTime} GMT+00:00`).toLocaleString()}</span></div>
            <div>
              <span>{intl.get(getIntlKey("status"))}</span>
              <span>{order?.meoStatus}</span></div>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <Spin spinning={spinning} className="swap-spin">
      <div className="meo-container">
        <Header headerClass="blue-header" activeKey="Meo" />
        <div className="container-box">
          {clientWidth < 750
            ? announcementFlag
              ?
              <div className='announcement-info' >
                <div className='announcement-icon'>
                  <img src={emileLogo} onClick={() => {
                    window.location = 'mailto:metacity.business@tokau.io'
                  }}></img>
                  <div className='announcement-text'>
                    <p>{intl.get(getIntlKey("aboutprivateplacement"))}</p>
                    <p>{intl.get(getIntlKey("ifyouareaninvestmentinstitutionandareinterestedinprivateplacement,pleasecontactus"))}</p></div>
                  <img className='close-icon' src={CancleICON} alt='' onClick={() => { setAnnouncementFlag(false) }} />
                </div>
              </div>
              : null : null}

          {!showViewProfile
            ?
            <>
              <div className="ore-title">
                {clientWidth > 750 ? (
                  <img src={meobanner}></img>
                ) : (
                  <img src={mbmeobanner}></img>
                )}
              </div>
              < div className="ore-content">
                <div className="select-hain-box">
                  <div className="select-hain">
                    <div className="select-title">
                      <p>{intl.get(getIntlKey("selectchain"))}</p>
                    </div>
                    <Radio.Group onChange={selectChain} value={chain} buttonStyle="solid">
                      <Radio.Button value={'ETH'}><img alt="" src={ETH}></img>ETH</Radio.Button>
                      <Radio.Button value={'BSC'}><img alt="" src={BNB}></img>BSC</Radio.Button>
                      {/* <Radio.Button value={'TRON'}><img alt="" src={TRON}></img>TRON</Radio.Button> */}
                    </Radio.Group>
                  </div>
                  <div className="select-hain ">
                    <div className="select-title">
                      <p>{intl.get(getIntlKey("selecttoken"))}</p>
                    </div>
                    {renderTokenByChain}
                  </div>
                </div>
                <div className="content-amount">
                  <div className="amount-top">
                    <p>From </p>
                    <div className="Max-btn">
                      {/* <p>balance: {balance}</p> */}
                      <p> ≈ </p>
                      <p> $ {tokenAmountToUSDT}</p>
                    </div>
                  </div>
                  <div className="amount-down">
                    <p className="from-input">
                      <Input
                        placeholder="1"
                        bordered={false}
                        type="number"
                        min={0}
                        value={tokenAmount}
                        onChange={(e) => {
                          if (e?.target?.value === "") {
                            return setTokenAmount("");
                          }
                          setTokenAmount(e?.target?.value);
                        }}
                      />
                    </p>
                  </div>
                </div>
                <div className="ore-show">
                  <img src={Exchange}></img>
                </div>
                <div className="content-amount">
                  <div className="amount-top">
                    <p>To </p>
                    <div className="Max-btn">
                      {/* <p> ≈ </p>
                    <p> $ 328.8655</p> */}
                    </div>
                  </div>
                  <div className="amount-down">
                    <p>
                      ≈  {receiveMeoAmount}
                    </p>
                    <div className="tokaulogo">
                      <img src={meologo}></img>
                      <p>MEO</p></div>
                  </div>
                </div>
                {chain === 'TRON' &&
                  <div className="content-amount" style={{ marginTop: '0.2rem' }}>
                    {/* <div className="amount-top">
                    <p> </p>
                    <div className="Max-btn">
                    </div>
                  </div> */}
                    <div className="amount-down">
                      <p style={{ width: '100%' }} className="enter-address">
                        <Input
                          placeholder={intl.get(getIntlKey("enteryourethereumwalletaddress"))}
                          bordered={false}
                          value={tronEthAddress}
                          style={{ width: '100%' }}
                          onChange={(e) => {
                            if (e?.target?.value === "") {
                              return setTronEthAddress("");
                            }
                            setTronEthAddress(e?.target?.value);
                          }}
                        />
                      </p>
                    </div>
                  </div>
                }
                <div className="exchange-show" >
                  <div className="exchange-left">
                    <img src={Info}></img>
                    <p>{intl.get(getIntlKey("exchangeprice"))}</p></div>
                  <div className="exchange-right">
                    <p>{parseFloat(Number(1) / Number(meoPrice)).toFixed(2)}</p>
                    <p>MEO/USDT</p>
                  </div>
                </div>
                <MetaButton
                  className="ore-btn"
                  text={meoTxHashStatus === 'pending' ? 'PENDING' : intl.get(getIntlKey("exchange"))}
                  style={{
                    height: "60px",
                    lineHeight: "60px",
                    fontSize: "20px",
                    cursor: pending ? "not-allowed" : "pointer",
                  }}
                  onClick={() => { confirmIdo() }}
                />
              </div></>
            :
            <>
              <div className="ore-title">
                {clientWidth > 750 ? (
                  <img src={Success}></img>
                ) : (
                  <img src={mbSuccess}></img>
                )}
              </div>
              <div className="exchange-code">
                <div className="back-icon" onClick={() => { setShowViewProfile(false) }}>
                  <img alt="" src={backIcon} />
                </div>
                <div className="exchange-code-title">{intl.get(getIntlKey("exchangecode"))}</div>
                <div className="exchange-code-box">

                  <div className="exchange-code-content">
                    <div className="exchange-code-num">
                      <p>{intl.get(getIntlKey("exchangecode"))}</p>
                      <p>{exchangeCode || ''}</p>
                    </div>
                    <div className="exchange-code-meo">
                      <p>{meoAmount} </p>
                      <div className="exchange-code-meologo">
                        <img src={meologo}></img>
                        <p>MEO</p>
                      </div>
                    </div>
                    <div className="exchange-code-des">{intl.get(getIntlKey("thetransactionissuccessful.belowisyourexchangecode,youcanuseittoexchangemeotokenonthe8thofaugust."))}
                    </div>
                    <MetaButton
                      className="exchange-btn"
                      text={intl.get(getIntlKey("viewonprofile"))}

                      onClick={() => { history.push('/Myprofile?showtab=4') }}
                    />
                    <div className="exchange-code-tip">{intl.get(getIntlKey("exchangemeotokenonthe8thofaugust."))}</div>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
        {clientWidth > 750
          ? announceEmile
            ? <div className="emile-position">
              <div className="emile-box">
                <div className="emile-text">
                  <p>{intl.get(getIntlKey("aboutprivateplacement"))}</p>
                  <p>{intl.get(getIntlKey("ifyouareaninvestmentinstitutionandareinterestedinprivateplacement,pleasecontactus"))}</p>
                </div>
                <MetaButton
                  className="emile-btn"
                  text={intl.get(getIntlKey("contactus"))}
                  onClick={() => {
                    window.location = 'mailto:metacity.business@tokau.io'
                  }}
                />
                <div className="cancel-btn" onClick={() => { setAnnounceEmile(false) }}></div>
                {/* <a href='mailto:metacity.business@tokau.io'>test</a> */}
              </div>
            </div>
            : null : null}

        <div className="box-detail history-transaction">
          {clientWidth > 750 ? (
            <div>
              <p className="map-title detail-title">
                {intl.get(getIntlKey("mytransactionhistory"))}
              </p>
              {/* <img alt='' src={boxDetailBg} className='box-detail-bg' /> */}
              <div className="detail-content">
                <div className="left-box"></div>
                <div className="right-box">
                  {/* {clientWidth > 750 ? <img alt='' src={tableBg} className='table-bg' /> : <img alt='' src={mbboxDetailBg} className='table-bg' />} */}
                  {/* <p className='map-title'>Price & Amount</p> */}
                  <div className="map-table">
                    <div className="map-table-label">
                      <span>{intl.get(getIntlKey("chain"))}</span>
                      <span>{intl.get(getIntlKey("token"))}</span>
                      <span>{intl.get(getIntlKey("amount"))}</span>
                      <span>Meo</span>
                      <span>{intl.get(getIntlKey("price"))}</span>
                      <span>{intl.get(getIntlKey("transactionhash"))}</span>
                      <span>{intl.get(getIntlKey("date"))}</span>
                      <span>{intl.get(getIntlKey("status"))}</span>
                      {/* <span>Meo Hash</span> */}
                      {/* <span></span> */}
                    </div>
                    {address &&
                      orderList?.map((order, index) =>
                        orderItem(order, index)
                      )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <p
                className="map-title detail-title"
                style={{ marginBottom: "1.28rem", marginTop: "-3.7067rem" }}
              >
                {intl.get(getIntlKey("mytransactionhistory"))}
              </p>
              <img alt="" src={boxDetailBg} className="box-detail-bg" />
              <div className="detail-content">
                <div className="left-box"></div>
                <div className="right-box">
                  <div className="map-table">
                    {address &&
                      orderList?.map((order, index) =>
                        orderItem(order, index)
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </Spin >
  );
};

export default connector(MeoPage);
