import intl from "react-intl-universal";
import localStorage from "localStorage";

const EventEmitter = require('events').EventEmitter;
EventEmitter.defaultMaxListeners = 2;
export const emit = new EventEmitter();

export const getLanguageJson = async (val) => {
  let lang = localStorage.getItem("language");
  lang = val || lang || "en_US";
  if(!(['en_US','zh_CN','ja_JP'].includes(lang))){
    lang = 'en_US';
  }
  localStorage.setItem("language", lang);
  const data = require(`../language/${lang}.json`);
  setTimeout(() => {
    emit.emit('change_language', lang);
  }, 1000);
  intl.init({
    currentLocale: lang,
    locales: {
      [lang]: data,
    },
  });
};
export const getIntlKey = (val) => {
  if (val !== undefined && val !== "") {
    val = val.toLowerCase().replace(/\s+/g, "")
  }
  return val
};

export const getLanguage = () => {
  return localStorage.getItem("language");
};
