import { sleep } from "../util/common";
import { getTxHashInfo } from "../util/contract/ore2";


const OreModel = {
  namespace: 'ore',
  state: {
    stakeTxHash: '',
    withdrawTxHash: '',
    stakeTxHashState: 'init',
    withdrawTxHashState: 'init',
    counter: 1,
  },

  effects: {
    *startStake({ payload }, { call, put, select }) {

      if (!payload || payload.stakeTxHash === "" || payload.stakeTxHash == undefined) {
        return // console.log("start interval buy land wrong");
      }
      console.log('enter start stake')
      yield put({
        type: "setState",
        payload: {
          startInterval: true,
          stakeTxHash: payload.stakeTxHash,
          // transferStatus: "init",
        },
      });
      while (true) {
        const stakeTxHash = yield select(({ ore }) => ore.stakeTxHash);
        const counter = yield select(({ ore }) => ore.counter);
        // 跳出循环
        if (stakeTxHash === '') {
          break;
        }
        const receipt = yield getTxHashInfo(stakeTxHash)
        console.log('receitp: ', receipt)
        console.log('receipt?.status', receipt?.status || '');
        console.log('stakeTxHash', stakeTxHash || '');
        if (receipt) {
          if (receipt.status === true) {
            yield put({
              type: "setState",
              payload: {
                stakeTxHashState: 'success',
                counter: 1,
              },
            });
            break;
          } else if (receipt.status === false) {
            yield put({
              type: "setState",
              payload: {
                stakeTxHashState: 'error',
                counter: 1,
              },
            });
            break;
          }

        } else {
          yield put({
            type: "setState",
            payload: {
              counter: (Number(counter) || 1) + 1,
            },
          })
        }
        yield call(sleep, 2000);

      }
    },

    *startWithdraw({ payload }, { call, put, select }) {
      console.log('startWithdraw');
      if (!payload || payload.withdrawTxHash === "" || payload.withdrawTxHash == undefined) {
        return // console.log("start interval buy land wrong");
      }
      console.log('enter withdraw')
      yield put({
        type: "setState",
        payload: {
          withdrawTxHash: payload.withdrawTxHash,
        },
      });
      while (true) {
        const withdrawTxHash = yield select(({ ore }) => ore.withdrawTxHash);
        const counter = yield select(({ ore }) => ore.counter);
        // 跳出循环
        if (withdrawTxHash === '') {
          break;
        }
        const receipt = yield getTxHashInfo(withdrawTxHash)
        console.log('receitp: ', receipt)
        console.log('receipt?.status', receipt?.status || '');
        console.log('withdrawTxHash', withdrawTxHash || '');
        if (receipt?.status === true) {
          yield put({
            type: "setState",
            payload: {
              withdrawTxHashState: 'success',
              counter: 1,
            },
          });
          break;
        } else if (receipt?.status === false) {
          yield put({
            type: "setState",
            payload: {
              withdrawTxHashState: 'error',
              counter: 1,
            },
          });
          break;
        } else {
          yield put({
            type: "setState",
            payload: {
              counter: (Number(counter) || 1) + 1,
            },
          })
        }
        yield call(sleep, 2000);

      }
    },

    *stateClear({ payload }, { call, put }) {
      console.log('stateClear');
      yield put({
        type: "setState",
        payload: {
          stakeTxHash: '',
          withdrawTxHash: '',
          stakeTxHashState: 'init',
          withdrawTxHashState: 'init',
          counter: 1,
        },
      });
    },
  },



  reducers: {
    setState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  }
}

export default OreModel