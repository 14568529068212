import axios from "../service/request";
import { sleep } from "../util/common";

const BuyLandModel = {
  namespace: "buyland",

  state: {
    startInterval: false,
    xIndex: "0",
    yIndex: "0",
    transferStatus: "init",
    blockInfoR: {},
    txHash: '',
    priceInfoR: [],
  },

  effects: {
    *start({ payload }, { call, put, select }) {
      // console.log("exec buy land loop");
      if (!payload || payload.snBatch == "" || payload.snBatch == undefined) {
        return // console.log("start interval buy land wrong");
      }
      yield put({
        type: "setState",
        payload: {
          startInterval: true,
          snBatch: payload.snBatch,
          // transferStatus: "init",
        },
      });
      while (true) {
        const snBatch = yield select(({ buyland }) => buyland.snBatch);
        // 跳出循环
        if (snBatch === '') {
          break;
        }
        let resRedeemDetail = {};
        const { landCode } = payload;
        if (landCode === 'TOKAU Homeland！！！！！') { // 暂时不用这个接口
          resRedeemDetail = yield call(
            axios.get,
            `/metacityOrder/getIsPayBySnBatch?snBatch=${snBatch}`
          );
        } else {
          resRedeemDetail = yield call(
            axios.get,
            `/metacityOrder/getOrderListBySnBatch?snBatch=${payload.snBatch}`
          );
        }
        if (resRedeemDetail?.code === 0 && resRedeemDetail?.data) {
          const snList = resRedeemDetail.data;
          const successList = snList.filter(item => (item.isPay === 'success'));
          const errorList = snList.filter(item => (item.isPay === 'error'));
          // 订单还在处理中
          let transferStatus = 'init';
          let startInterval = true;
          let snBatch = payload.snBatch;
          // 全部成功
          if (successList?.length === snList?.length) {
            transferStatus = 'success';
            // transferStatus = 'success';
            startInterval = false;
            snBatch = '';
          } else if (successList?.length >= 0 && (errorList?.length + successList?.length) === snList?.length) {
            // 部分成功 并退款
            transferStatus = 'partSuccess';
            startInterval = false;
            snBatch = '';
          } else if (errorList?.length === snList?.length) {
            // 全部失败
            transferStatus = 'fail';
            startInterval = false;
            snBatch = '';
          } else {
            // 交易详情
            transferStatus = 'snList';
            startInterval = false;
          }
          yield put({
            type: "setState",
            payload: {
              transferStatus,
              startInterval,
              snBatch,
              snList,
            }
          })
        }
        yield call(sleep, 2000);
      }
    },

    *stop({ payload }, { call, put }) {
      // console.log("exec stop ");
      yield put({
        type: "setState",
        payload: {
          startInterval: false,
          snBatch: "",
          transferStatus: "init",
        },
      });
    },

    *stateClear({ payload }, { call, put }) {
      yield put({
        type: "setState",
        payload: {
          startInterval: false,
          snBatch: "",
          transferStatus: "init",
        },
      });
    },

    *getLandDetail({ payload }, { call, put }) {
      // console.log('enter get land detail')
      // console.log('land detail payload: ', payload)
      let resRedeemDetail = yield call(
        axios.get,
        `/metacityLand/findByLocation?xIndex=${payload.xIndex}&yIndex=${payload.yIndex}&landCode=${payload.landCode}`
      );
      // console.log('land detail data: ', resRedeemDetail)
      if (
        resRedeemDetail &&
        resRedeemDetail.code == 0 &&
        resRedeemDetail.data
      ) {
        let data = resRedeemDetail.data;
        // console.log('start interval: ', data?.status === 'success' && data?.sold === 'yes' )
        yield put({
          type: "setState",
          payload: {
            xIndex: payload.xIndex,
            yIndex: payload.yIndex,
            transferStatus: data.status == "success" ? 'success' : 'init',
            blockInfoR: data,
          },
        });
        //查看土地价格
        let landPricesRes = yield call(axios.post, '/metaCityLandPrice/findByIds', data.payPrices, {
          headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          }
        })
        // console.log('land prices info r: ', landPricesRes)
        if (landPricesRes && landPricesRes.code === 0) {

          yield put({
            type: "setState",
            payload: {
              priceInfoR: landPricesRes.data
            },
          });
        }
      }
    },
  },

  reducers: {
    setState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default BuyLandModel;
