// import { assert } from "console";
// import { ethers } from "ethers";

const { ethers } = require("ethers");

// rarity = 0, 1, 2
const maxRarity = 1000;

//  x and y coordinate must be in [0, maxCoord - 1]
const maxCoord = 10000;

// max number of cities, numbered from 0 to 9999
const maxCityNumber = 10000;

// get tokenId from city, coord x, coord y and rarity
// parameter: 
// city: number, coord_x: number, coord_y: number, rarity: number
// return:
// tokenId: ethers.BigNumber
function getIdFromLandInfo(city, coord_x, coord_y, rarity) {
  console.assert(city < maxCityNumber && coord_x < maxCoord && coord_y < maxCoord && rarity < maxRarity);

  var encodedId = ethers.BigNumber.from(coord_x).mul(maxCoord).add(coord_y).mul(maxCityNumber).add(city).mul(maxRarity).add(rarity);
  return encodedId;
}

// get city, coord x, coord y and rarity from tokenId
// parameter: 
// tokenId: ethers.BigNumber
// return:
// city: number, coord_x: number, coord_y: number, rarity: number
function getLandInfoFromId(tokenId) {
  tokenId = ethers.BigNumber.from(tokenId)
  var rarity = tokenId.mod(maxRarity);
  tokenId = tokenId.div(maxRarity);

  var city = tokenId.mod(maxCityNumber);
  tokenId = tokenId.div(maxCityNumber);

  var coord_y = tokenId.mod(maxCoord);
  var coord_x = tokenId.div(maxCoord);
  return [Number(city), Number(coord_x), Number(coord_y), Number(rarity)];
}

module.exports = { getIdFromLandInfo, getLandInfoFromId, maxRarity };