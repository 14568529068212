import axios from 'axios';

import { BASE_URL, TIMEOUT} from './config';

const instance = axios.create({
    baseURL:BASE_URL,
    timeout:TIMEOUT
})

instance.interceptors.request.use(config => {
    config.headers['Access-Control-Allow-Origin'] = '*';
    return config
},error => {

})

instance.interceptors.response.use(res => {
    
    return res.data
},error => {
    return error;
})

export default instance;

