import React, { useEffect, useLayoutEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Toast } from "antd-mobile";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './index.less';
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import { MyWalletConnect } from "@/util/myWalletConnect/myWalletConnect";
import WalletConnectModal from "../WalletConnectModal";
import MyModal from "../MyModal";
import dsconnect from "@/asset/imgs/Modal/dsconnect.svg";
import copyIcon from "@/assets/imgs/copyIcon.png";
import viewMyProfile from "@/asset/imgs/Modal/User.svg";
import copyAddress from "@/assets/imgs/copyAddress.png";
import { switchChain } from "../../util/contract/bridge";
import { Menu, Dropdown, Typography } from 'antd';
import intl from "react-intl-universal";
import { getIntlKey, getLanguage, emit, getLanguageJson } from "@/util/language";
import copy from "../../asset/imgs/Modal/copy.png";
import { checkChain } from "../../util/common";

const { SubMenu } = Menu;
const { Paragraph } = Typography;

export const getShowAddress = (address, length) => {
  if (address && address.length >= 11) {
    return address.substring(0, 7) + '...' + address.substring(address.length - 4);
  }
  return ''
}

export const PcMenu = ({ address, activeKey = 'Mint' }) => {
  const history = useHistory();

  return (
    <Menu mode="horizontal" className="pc-menu-container" subMenuCloseDelay={1}>
      <SubMenu
        key="Home"
        title={intl.get(getIntlKey("home"))}
        className={activeKey === 'Home' ? "sub-menu active-sub-menu" : "sub-menu"}
        onTitleClick={() => {
          if (activeKey === 'Home') {
            return
          }
          history.push("/Home");
        }}
      >
      </SubMenu>
      <SubMenu
        key="Mint"
        title={intl.get(getIntlKey("mint"))}
        className={activeKey === 'Mint' ? "sub-menu active-sub-menu" : "sub-menu"}
        onTitleClick={() => {
          if (activeKey === 'Mint') {
            return
          }
          history.push("/Mint");
        }}
      >
      </SubMenu>
      <SubMenu
        key="3ddemo"
        title="3D Demo"
        className={activeKey === '3ddemo' ? "sub-menu active-sub-menu" : "sub-menu"}
        onTitleClick={() => {
          if (activeKey === '3ddemo') {
            return
          }
          window.open("https://3d-metacity.tokau.io/");
        }}
      >
      </SubMenu>
      <SubMenu
        key="Celebrities"
        title={intl.get(getIntlKey("celebrities"))}
        className={activeKey === 'Celebrities' ? "sub-menu active-sub-menu" : "sub-menu"}
        onTitleClick={() => {
          if (activeKey === 'Celebrities') {
            return
          }
          history.push("/Celebrities");
        }}
      >
      </SubMenu>
      <SubMenu
        key="Roadmap"
        title={intl.get(getIntlKey("roadmap"))}
        className={activeKey === 'Roadmap' ? "sub-menu active-sub-menu" : "sub-menu"}
        onTitleClick={() => {
          if (activeKey === 'Roadmap') {
            return
          }
          history.push("/Roadmap");
        }}
      >
      </SubMenu>
      <SubMenu
        key="Meo"
        title="MEO Swap"
        className={activeKey === 'Meo' ? "sub-menu active-sub-menu" : "sub-menu"}
        onTitleClick={() => {
          if (activeKey === 'Meo') {
            return
          }
          history.push("/Meo");
        }}
      >
      </SubMenu>
      {/* <SubMenu
        key="Swap"
        title='Swap'
        className={(activeKey === 'Swap' || activeKey === 'Ore') ? "sub-menu active-sub-menu" : "sub-menu"}
      >
        <SubMenu
          key="Swap"
          title='TBS Swap'
          className={activeKey === 'Swap' ? "sub-menu active-sub-menu" : "sub-menu"}
          onTitleClick={() => {
            if (activeKey === 'Swap') {
              return
            }
            history.push("/Swap");
          }}
        >
        </SubMenu>
        <SubMenu
          key="Ore"
          title='ORE Swap'
          className={activeKey === 'Ore' ? "sub-menu active-sub-menu" : "sub-menu"}
          onTitleClick={() => {
            if (activeKey === 'Ore') {
              return
            }
            history.push("/Ore");
          }}
        >
        </SubMenu>
      </SubMenu> */}
      <SubMenu
        key="Doc"
        title="Doc"
        className={activeKey === 'Doc' ? "sub-menu active-sub-menu" : "sub-menu"}
        onTitleClick={() => {
          if (activeKey === 'Doc') {
            return
          }
          window.open("https://metacity-pdf.tokau.io/MetaCityWhitePaperEN");
        }}
      >
      </SubMenu>
      <SubMenu
        key="Myprofile"
        title={intl.get(getIntlKey("myprofile"))}
        className={activeKey === 'Myprofile' ? "sub-menu active-sub-menu" : "sub-menu"}
        onTitleClick={() => {
          if (activeKey === 'Myprofile') {
            return
          }
          history.push('/Myprofile');
        }}
      ></SubMenu>
    </Menu>
  );
};

const mapStateToProps = ({ login, webstyle }) => {
  // console.log(webstyle);
  return {
    login: login,
    address: login.address,
    guildId: login.guildId,
    businessId: login.businessId,
    showAddress: login.showAddress,
    clientWidth: webstyle.clientWidth,
    avatar: login.avatar,
    limitedLandCodes: login.limitedLandCodes,
  };
};
const connector = connect(mapStateToProps);

const NewHeader = ({
  showAddress,
  address,
  dispatch,
  clientWidth,
  login,
  headerClass = "white-header",
  avatar,
  limitedLandCodes,
  businessId,
  guildId,
  activeKey,
}) => {
  const history = useHistory();
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [network, setNetwork] = useState('');
  const [preload, setPreload] = useState(false);
  const [update, setUpdate] = useState(false);

  const switchChainMenu = (
    <Menu>
      <Menu.Item key="BSC" onClick={async () => {
        const switchFlag = await switchChain('BSC');
        if (switchFlag) {
          setNetwork('BSC')
        }
      }}>
        BSC
      </Menu.Item>
      <Menu.Item key="ETH" onClick={async () => {
        const switchFlag = await switchChain('ETH');
        if (switchFlag) {
          setNetwork('ETH')
        }
      }}>
        ETH
      </Menu.Item>
    </Menu>
  );

  const handelLanguageChange = (val) => {
    getLanguageJson(val);
    setTimeout(() => {
      history.push(history.location.pathname);
      setUpdate(!update);
    }, 1000)
  }

  const switchLanguageMenu = (
    <Menu className="uglobe-drop-down">
      <Menu.Item key="en_US" onClick={() => {
        handelLanguageChange("en_US");
      }}> <span style={{ color: '#262626' }}>English</span> </Menu.Item>
      <Menu.Item key="zh_CN" onClick={() => {
        handelLanguageChange("zh_CN");
      }}> <span style={{ fontWeight: 'bold', color: '#262626' }}>中文繁体</span> </Menu.Item>
      <Menu.Item key="ja_JP" onClick={() => {
        handelLanguageChange("ja_JP");
      }}> <span style={{ fontWeight: 'bold', color: '#262626' }}>日本語</span> </Menu.Item>
      {/* <Menu.Item key="ko_KR" onClick={() => {
        handelLanguageChange("ko_KR");
      }}> 한국어 </Menu.Item> */}
    </Menu>
  );

  useEffect(() => {
    init();

    document.body.onresize = () => {
      dispatch({
        type: "webstyle/setContext",
        payload: {
          clientWidth: document.body.clientWidth,
        },
      });
    };
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  // address改变，设置分销URL
  useEffect(() => {
    console.log('address', address);
    if (address) {
      setUpper();
    }
  }, [address])

  const setUpper = async () => {
    console.log('setUpper');
    try {
      const parentsId = localStorage.getItem("parentsId");
      if (parentsId && parentsId !== 1) {
        await dispatch({
          type: "login/setUpper",
          payload: {
            address,
            h: parentsId,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const init = async () => {
    checkChain()
  }
  const metamaskLogin = async () => {
    console.log('metamaskLogin');
    dispatch({
      type: "login/login",
      payload: {},
    });
  };

  const loginFunc = async () => {
    if (window?.ethereum && !address) {
      metamaskLogin();
    } else if (!window?.ethereum) {
      if (!address) {
        return dispatch({
          type: 'login/showLoginModal',
          payload: {
            showLoginModal: true
          }
        })
      }
      const walletConnector = MyWalletConnect.getInstance();
      if (!walletConnector.connected) {
        // walletConnector.createSession();
        dispatch({
          type: 'login/showLoginModal',
          payload: {
            showLoginModal: true
          }
        })
        // dispatch({
        //   type: "login/login",
        //   payload: {
        //     loginType: 'wallect',
        //   },
        // });
      }
    }
  };

  const PCHeader = () => (
    <div className={`pc-header-white ${headerClass}`}>
      <div className="pc-header-logo" onClick={() => { history.push('/home') }}></div>
      <PcMenu address={address} activeKey={activeKey} />
      <div className="pc-header-btn">
        {/* <Dropdown overlay={switchChainMenu} trigger={['click']} >
          <span className="switch-btn">{network}</span>
        </Dropdown> */}
        <Dropdown overlay={switchLanguageMenu} trigger={['click']} >
          <span className="pc-uglobe" id="pcuglobe"></span>
        </Dropdown>
        <div
          className="pc-connect-btn"
          onClick={() => {
            if (!address) {
              loginFunc();
            } else {
              setShowAccountModal(true);
            }
          }}
        >
          {address ? <img src={avatar?.url} alt="" /> : null}
          {address ? showAddress : intl.get(getIntlKey("connectwallet"))}
        </div>
      </div>
    </div>
  )

  const MobileHeader = () => (
    <div className={`header-container ${headerClass}`}>
      <div className="header-logo" onClick={() => { history.push('/home') }}></div>
      {/* <Dropdown overlay={switchChainMenu} trigger={['click']} >
        <span className="switch-btn">{network}</span>
      </Dropdown> */}
      <Dropdown overlay={switchLanguageMenu} trigger={['click']} getPopupContainer={() => document.getElementById('uglobe')} >
        <span id='uglobe' className="icon uglobe"></span>
      </Dropdown>
      {showAddress ? (
        <span className="user-addr">
          {avatar?.url ? <img src={avatar?.url} alt="" /> : null}
          <span onClick={() => setShowAccountModal(true)}>
            {showAddress}
          </span>
        </span>
      ) : (
        <span
          className="user-addr"
          onClick={() => {
            if (!address) {
              loginFunc();
            } else {
              setShowAccountModal(true);
            }
          }}
        >
          {intl.get(getIntlKey("connectwallet"))}
        </span>
      )}
    </div>
  )

  return (
    <>
      {clientWidth <= 750 ? <MobileHeader /> : <PCHeader />}
      <WalletConnectModal />
      <MyModal
        className="my-modal-container account-modal-container"
        visible={showAccountModal}
        title="My Account"
        closable={true}
        onClose={() => {
          setShowAccountModal(false);
        }}
        modalContent={
          <div className="account-modal">
            <p className="account-des">Connect with Metamask</p>
            <div className="account-info">
              <div className="account-avatar">
                <img src={avatar?.url} alt="" />
              </div>
              <div className="account-address">
                <Paragraph className='copy-btn' copyable={{ icon: <img src={copy} style={{ marginLeft: "0px", width: "23px", marginBottom: "5px" }}></img> }} style={{ zIndex: "5", color: "#1A2786" }}>{address}</Paragraph>
              </div>
              {/* <div className="my-modal-button" onClick={() => {
                localStorage.removeItem("walletconnect");
                dispatch({
                  type: "login/logout",
                });
                setShowAccountModal(false);
              }}>change</div> */}
            </div>
            <div className="account-btn">
              {/* <div className="icon-and-btn">
                <CopyToClipboard
                  text={address}
                  onCopy={() => {
                    if (address) {
                      Toast.info("copied!");
                    }
                  }}
                >
                  <img src={copyAddress} alt="" />
                </CopyToClipboard>
              </div> */}
              <div
                className="icon-and-btn"
                onClick={() => {
                  if (history.location.pathname?.includes('Myprofile')) {
                    setShowAccountModal(false);
                  }
                  history.push('/Myprofile');
                }}
              >
                <img src={viewMyProfile} alt="" />
                View Profile
              </div>
              <div
                className="icon-and-btn"
                onClick={() => {
                  console.log('logout');
                  localStorage.removeItem("walletconnect");
                  dispatch({
                    type: "login/logout",
                  });
                  setShowAccountModal(false);
                }}
              >
                <img src={dsconnect} alt="" />
                Disconnect
              </div>
            </div>
            <p className="account-footer">
              Your Assets can be check on your profile...
            </p>
          </div>
        }
      />
      {preload ? <div className="preload-img"></div> : null}
    </>
  );
};


export default connector(NewHeader);
