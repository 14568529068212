import './Footer.sass';
import footerlogo from "@/asset/imgs/footer/footerlogo.png";
import twitter from "@/asset/imgs/footer/twitter.svg";
import instagram from "@/asset/imgs/footer/instagram.svg";
import telegram from "@/asset/imgs/footer/telegram.svg";
import medium from "@/asset/imgs/footer/medium.svg";
import tree from "@/asset/imgs/footer/tree.png";
import discord from "@/asset/imgs/footer/discord.svg";


const Footer = () => {
  return (
    <div className="footer-color">
      <div className="meta-footer">
        <div className='footer-col-one'>
          <div><img src={footerlogo}></img></div>
          <div className='share-link'>
            <img src={twitter} onClick={() => {
              window.open("https://twitter.com/MetaCityTlabs");
            }}></img>
            <img src={discord} onClick={() => {
              window.open("https://discord.gg/themetacity");
            }}></img>
            <img src={instagram} onClick={() => {
              window.open("https://www.instagram.com/metacitytlabs/");
            }}></img>
            <img style={{ marginRight: 0 }} src={telegram} onClick={() => {
              window.open("https://t.me/TLabs_metacity");
            }}></img>
            {/* <img src={medium} onClick={() => {
              window.open("https://medium.com/@tlabs");
            }}></img> */}
          </div>
          <div className='tree'>
            <img src={tree}></img>
          </div>
        </div>
      </div>
      <div className='footer-tar'>© 2022 Metacity. </div>
    </div>
  )
}
export default Footer;
