import {create, Model} from 'dva-core';
import { persistEnhancer } from 'dva-model-persist';
import loginModel from './model/LoginModel';
import redeemModel from './model/RedeemModel';
import buyLandModel from './model/BuyLandModel';
import webStyleModel from './model/WebStyleModel';
import cartModel from './model/CartModel';
import oreModel from './model/OreModel';
import meoModel from './model/MeoModel';


const app = create();
app.model(loginModel);
app.model(redeemModel);
app.model(buyLandModel);
app.model(webStyleModel);
app.model(cartModel);
app.model(oreModel);
app.model(meoModel);

app.use({
  extraEnhancers: [
    persistEnhancer()
  ],
});
app.start();
export default app._store;

