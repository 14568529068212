import { Modal, Toast } from 'antd-mobile';
import React from 'react'
import { connect } from "react-redux";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import MyModal from '@/components/MyModal'
import MetamaskPng from "@/assets/imgs/metamask2.png";
import WalletConnectPng from "@/assets/imgs/walletConnect.png";
import { useEffect, useState } from 'react';
import { MyWalletConnect } from '../../util/myWalletConnect/myWalletConnect';
import './index.sass';
import MetaButton from '../../components/MetaButton';

const { clientWidth } = document.body;
const mapStateToProps = ({ login }) => {
  return {
    username: login.username,
    login: login.login,
    loginType: login.loginType,
    showLoginModal: login.showLoginModal,
    address: login.address,
    showAddress: login.showAddress,
    nickname: login.nickname,
  };
};
const connector = connect(mapStateToProps);

/**
 * 
 * wallet connect modal
 */
const WalletConnectModalComponnent = ({
  dispatch,
  address,
  login,
  loginType,
  showLoginModal,
  showAddress,
}) => {

  const [hasMetamask, setHasMetamask] = useState(false);

  const walletConnectLogin = async () => {

    const tmpConnector = MyWalletConnect.getInstance();
    // tmpConnector.createSession()
    console.log('tmpConnector.connected', tmpConnector.connected);
    if (!tmpConnector.connected) {
      await tmpConnector.createSession();
    }
    console.log('address', address);

    if (!address) {
      dispatch({
        type: 'login/login',
        payload: {
          loginType: 'wallect',
        }
      })
    }


    tmpConnector.on("connect", (error, payload) => {
      if (error) {
        Toast.info("wallect connect error!!")
        throw error;
      }

      // Get provided accounts and chainId
      const { accounts, chainId } = payload.params[0];

      const account = accounts[0];
      // console.log("wallet connect chainId: ", chainId);
      // console.log("wallet connect account: ", account);

      dispatch({
        type: "login/login",
        payload: {
          address: account,
          loginType: 'wallect',
          login: true,
        },
      });
    });
    tmpConnector.on("disconnect", (error, payload) => {
      if (error) {
        throw error;
      }
      dispatch({
        type: 'login/logout'
      })
      // Delete connector
    });
    dispatch({
      type: 'login/showLoginModal',
      payload: {
        showLoginModal: false
      }
    })
  };

  useEffect(() => {
    if (!login) {
      if (window?.ethereum) {
        window?.ethereum.on("accountsChanged", async function (accounts) {
          // console.log("----- acount change ------");
          const account = accounts[0];

          dispatch({
            type: "login/login",
            payload: {
              address: account,
              showLoginModal: false,
            },
          });
          dispatch({
            type: "newMoney/getBalance",
            payload: {},
          });
        });
      } else {
        dispatch({
          type: 'login/showLoginModal',
          payload: {
            showLoginModal: false
          }
        })
        // walletConnectLogin()
      }
    }
  }, [login, loginType]);

  useEffect(() => {
    if (typeof window?.ethereum !== "undefined") {
      setHasMetamask(true);
    }
  })

  const metamaskLogin = async () => {
    // console.log("metamask login");
    dispatch({
      type: "login/login",
      payload: {},
    });
  };
  return <MyModal
    visible={showLoginModal}
    title={<span>Connect Wallet</span>}
    closable={true}
    onClose={() => {
      dispatch({
        type: 'login/showLoginModal',
        payload: {
          showLoginModal: false
        }
      })
    }}
    modalContent={
      <>
      {clientWidth > 750
          ? <div><div
          onClick={
            hasMetamask
              ? () => metamaskLogin()
              : () => { window.open("https://trustwallet.com//"); }
          }
          
        >
         <MetaButton
            className='login-btn'
            text={hasMetamask ? "MetaMask" : "Install Trust Wallet"}
          />
            <img 
            style={{
             position: "absolute",
             right:"90px",
             top: "88px",
             width: "28px",
            }} 
            className='modal-img' src={MetamaskPng} alt='' />
        </div>
        <MetaButton
            className='login-btn login-ConnectWalletbtn'
            text="Connect Wallet"
            onClick={() => walletConnectLogin()} 
          />
            <img className='modal-img modal-img2' src={WalletConnectPng} alt=''
             style={{
              position: "absolute",
              right:"90px",
              top: "150px",
              width: "24px",
             }}  />
          </div>
        : <div><div
          onClick={
            hasMetamask
              ? () => metamaskLogin()
              : () => { window.open("https://trustwallet.com//"); }
          }
          className="login-modal-btn1 "
        >
          <MetaButton
            className='mb-login-btn' 
            style={{
              fontSize: "0.84rem",
              marginLeft: "-1rem"
             }} 
            text={hasMetamask ? "MetaMask" : "Install Trust Wallet"}
          />
            <img 
            style={{
             position: "absolute",
             right:"2.5rem",
             top: "4.4rem",
             width: "1rem",
            }} 
            className='modal-img' src={MetamaskPng} alt='' />
        </div>
        <div className="login-modal-btn1 login-modal-btn2" onClick={() => walletConnectLogin()} >
          <div className='modal-btn1' >
            <div className='modal-text' > Connect Wallet </div>
            <img className='modal-img modal-img2' src={WalletConnectPng} alt='' />
          </div>
        </div></div>
        }
        
      </>
    }
  />
}

export default connector(WalletConnectModalComponnent);