import axios from 'axios';
import { ethers } from 'ethers';
import Web3 from 'web3';
import ERC20TokenAbiFile from "../util/contract/abi/TLabsToken.json";
import { BASE_URL } from './config';
const TronWeb = require('tronweb')

const testEnv = process.env.NODE_ENV === 'development';
const ETHUSDTReceiveAddress = '0xe94d0792734e952d1F7600436749412172e9Dc15';
const envChainTokenContractMap = {
  test: {
    ETH: {
      USDT: '0xD92E713d051C37EbB2561803a3b5FBAbc4962431'
    },
    BSC: {
      USDT: '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
      TBS: '0xA242034d5E0F8e98F92bB331d10fE0b86Acd8d00'
    }
  },
  prod: {
    ETH: {
      USDT: '0xdAC17F958D2ee523a2206206994597C13D831ec7'
    },
    BSC: {
      USDT: '0x55d398326f99059fF775485246999027B3197955',
      TBS: '0x45Fffed8d9651fE9EA0321fcC9b15Ee5e052A208'
    }
  }
}

const getNetUrl = (chainCode) => {
  if (testEnv) {
    if (chainCode === 'ETH') {
      return "https://rinkeby.infura.io/v3/";
    } else if (chainCode === 'BSC') {
      return "https://data-seed-prebsc-1-s2.binance.org:8545/";
    }
  }
  if (chainCode === 'ETH') {
    return "https://mainnet.infura.io/v3/";
  } else if (chainCode === 'BSC') {
    return "https://bsc-dataseed.binance.org";
  }

}

const getHexAmountByChainAndToken = (chain, token, amount) => {
  let tmpAmount = String(amount);
  console.log('transfer amount: ', amount)
  let r = null;
  if (chain === 'ETH' && token === 'USDT') {
    r = Web3.utils.toHex(Web3.utils.toWei(tmpAmount, 'Mwei'))
  } else {
    r = Web3.utils.toHex(Web3.utils.toWei(tmpAmount, 'ether'))
  }

  return r;
}

// get ETH or BSC transfer params
export const getETHOrBSCTransferParams = async (chainCode, tokenCode, amount, from) => {
  let netUrl = getNetUrl();
  const web3 = new Web3(window.ethereum || netUrl);
  const nonce = await web3.eth.getTransactionCount(from);
  const chainId = await web3.eth.getChainId();
  const gasPrice = await web3.eth.getGasPrice();
  let value = getHexAmountByChainAndToken(chainCode, tokenCode, amount);
  if (tokenCode === 'ETH' || tokenCode === 'BNB') {

    const transactionParameters = {
      nonce: Web3.utils.toHex(nonce), // ignored by MetaMask
      gasPrice: Web3.utils.toHex(gasPrice), // customizable by user during MetaMask confirmation.
      gas: '0x186a0', // customizable by user during MetaMask confirmation.
      to: ETHUSDTReceiveAddress, // Required except during contract publications.
      from: from, // must match user's active address.
      value: value, // Only required to send ether to the recipient from the initiating external account.
      data: '0x00', // Optional, but used for defining smart contract creation and interaction.
      chainId: chainId, // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
    };
    return transactionParameters;
  }
  // get contract
  let tmpEnv = testEnv ? 'test' : 'prod';
  let contractAddress = envChainTokenContractMap[tmpEnv][chainCode][tokenCode];
  console.log('tmpEnv: ', tmpEnv)
  console.log('tokenCode: ', tokenCode)
  console.log('contractAddress: ', contractAddress)
  console.log('getETHOrBSCTransferParams contractAddress: ', contractAddress)
  const ERC20Contract = new web3.eth.Contract(ERC20TokenAbiFile['abi'], contractAddress);
  const myData = await ERC20Contract.methods.transfer(ETHUSDTReceiveAddress, value).encodeABI();
  console.log('value: ', value)
  const unsigned = {
    from: from,
    to: contractAddress,
    value: '0x00',
    gasPrice: Web3.utils.toHex(gasPrice),
    nonce: Web3.utils.toHex(nonce),
    chainId: String(Web3.utils.toHex(chainId)),
    data: myData,
  };
  console.log('chainId: ', chainId)
  let gas = await web3.eth.estimateGas(unsigned);
  unsigned.gas = Web3.utils.toHex(gas);
  console.log('ido erc20 transfer unsigned: ', unsigned);
  return unsigned;

}

function isETHOrBSC(chainCode) {
  if (chainCode === 'ETH' || chainCode === 'BSC') {
    return true;
  }
  return false;
}

// get balance of user
export const idoGetBalanceOfUser = async (chainCode, tokenCode, userAddress) => {
  try {
    console.log(`user balance params: chainCode: ${chainCode}, tokenCode: ${tokenCode}, userAddress: ${userAddress} `)
    if (!userAddress || userAddress == '') {
      return ''
    }
    let tmpEnv = testEnv ? 'test' : 'prod';
    if (isETHOrBSC(chainCode)) {
      let netUrl = getNetUrl();
      const web3 = new Web3(window.ethereum || netUrl);
      if (tokenCode === 'ETH' || tokenCode === 'BNB') {
        let balance = await web3.eth.getBalance(userAddress);
        console.log('balance: ', balance)
        return balance;
      }
      let contractAddress = envChainTokenContractMap[tmpEnv][chainCode][tokenCode];
      console.log('contract address: ', contractAddress)
      const ERC20Contract = new web3.eth.Contract(ERC20TokenAbiFile['abi'], contractAddress);
      const balance = await ERC20Contract.methods.balanceOf(userAddress).call({ from: userAddress });
      console.log('balance: ', balance)
      return balance;
    } else {  // tron net
      if (testEnv && tokenCode === 'ETH') {
        return '1.12345'
      }
      if (!hasTronEnv()) return;
      let address = window.tronWeb.defaultAddress.base58;
      let contractAddress = getTronErc20ContractAddressByTokenCode(tokenCode);
      let contract = await window.tronWeb.contract().at(contractAddress);
      let result = await contract['balanceOf'](address).call();
      console.log(`tron tokenCode: ${tokenCode}, balance: ${result}`)
      return String(result);
    }
  } catch (error) {
    return ''
  }
}

// get tokenPrice by tokenCode
export const getTokenPriceByTokenCode = async (tokenCode) => {
  return axios.get(`https://api.binance.com/api/v3/avgPrice?symbol=${tokenCode}USDT`)
}

// get tbs price
export const getTBSPrice = async () => {
  let resp = await axios.get(BASE_URL + "ido/getTBSPrice")
  console.log('get tbs price resp: ', resp)
  return resp;
  
}

export const getMEOPrice = async () => {
  let resp = await axios.get(BASE_URL + "ido/getMeoPrice")
  console.log('get meo price resp: ', resp)
  return resp;
 
}

export const getMeoTxHashDetail = async (meoTxHash) => {
  let meoTxDetailResp = await axios.get(`${BASE_URL}ido/getIdoDetailByTxHash`, {
    params: {
      txHash: meoTxHash,
    }
  });
  if (meoTxDetailResp.status === 200 && meoTxDetailResp?.data?.code === 0) {
    return meoTxDetailResp.data.data;
  }
  return null;
}

function getTronReceiveAddress() {
  return 'TEEazvGw11eMEhStB4w2nZESqrcqc6Rdw7'
}

function getTronErc20ContractAddressByTokenCode(tokenCode) {
  if (testEnv) {
    if (tokenCode === 'USDT') {
      return 'TXLAQ63Xg1NAzckPwKHvzw7CSEmLMEqcdj'
    } else if (tokenCode === 'ETH') {
      return ''
    }
  } else {
    if (tokenCode === 'USDT') {
      return 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t'
    } else if (tokenCode === 'ETH') {
      return 'THb4CqiFdwNHsWsQCs4JhzwjMWys4aqCbF'
    }
  }
}

export const hasTronEnv = () => {
  if (window.tronWeb) {
    return true;
  }
  return false;
}

export const tronTransferErc20 = async (amount, tokenCode, senderEthAddress) => {

  var tronweb = window.tronWeb;
  var senderAddress = tronweb.defaultAddress.base58;
  var receiverAddress = getTronReceiveAddress();
  amount = String(amount);
  let finalAmount = '0';
  if (tokenCode === 'USDT') {
    finalAmount = Web3.utils.toWei(amount, 'Mwei')
  } else if (tokenCode === 'ETH') {
    finalAmount = Web3.utils.toWei(amount, 'ether')
  }
  var parameter = [{ type: 'address', value: receiverAddress }, { type: 'uint256', value: finalAmount }]
  var options = {
    feeLimit: 100000000
  }

  const transactionObject = await tronweb.transactionBuilder.triggerSmartContract(
    tronweb.address.toHex(getTronErc20ContractAddressByTokenCode(tokenCode)),
    "transfer(address,uint256)",
    options,
    parameter,
    tronweb.address.toHex(senderAddress)
  );
  var signedTransaction = await tronweb.trx.sign(transactionObject.transaction);
  var broadcastTransaction = await tronweb.trx.sendRawTransaction(signedTransaction);
  console.log(broadcastTransaction);
  addIdoTxTron(broadcastTransaction?.txid, senderEthAddress).then(res => {
    console.log('add tronTxItem res: ', res)
  })
  return broadcastTransaction?.txid;
}

const addIdoTxTron = (txHash, senderEthAddress) => {
  let postBody = {
    txHash: txHash,
    senderEthAddress: senderEthAddress
  }
  console.log('addIdoTxTron post body: ', postBody)
  return axios.post(BASE_URL + 'ido/addIdoTxItemTron', postBody)
}



