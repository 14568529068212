import React, { useState, useEffect } from 'react'
import './index.sass'
import menuBtn from "@/asset/imgs/header/menuBtn.png";
import menuClose from "@/asset/imgs/header/menuClose.png";
import { Mask } from 'antd-mobile';
import intl from "react-intl-universal";
import { getIntlKey } from "@/util/language";

const MobileMenu = (props) => {
  const [visible, setVisible] = useState(false)
  const [activeKey, setActiveKey] = useState(window.location.hash.replaceAll('#/', '') || '')
  useEffect(() => {
    window.onhashchange = function (event) {
      ["home", "mint", '3ddemo', "celebrities", "roadmap", 'swap', 'ore', 'meo', 'Doc', 'myprofile'].forEach(menuKey => {
        if (event?.newURL?.toLocaleLowerCase()?.includes(menuKey)) {
          setActiveKey(menuKey)
        }
      })
    }
  }, [])
  const handleMenuClick = (menuKey) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (menuKey === '3ddemo') {
      return window.open("https://3d-metacity.tokau.io/");
    }
    if (menuKey === 'Doc') {
      return window.open("https://metacity-pdf.tokau.io/MetaCityWhitePaperEN");
    }
    if (menuKey === activeKey) {
      return
    }
    window.location.replace(`/#/${menuKey}`);
    setActiveKey(menuKey);
    setVisible(false);
  }

  const MenuList = () => {
    return <Mask className="mobile-menu-mask" visible={visible} onMaskClick={() => setVisible(false)}>
      <div className='mobile-menu-list'>
        {/* "myprofile", SSSSWWWW */}
        {["home", "mint", '3ddemo', "celebrities", "roadmap", 'meo', 'Doc', 'myprofile'].map((menuKey) =>
          <div
            className={activeKey?.toUpperCase() === menuKey?.toUpperCase() ? 'active-menu' : ''}
            key={menuKey}
            onClick={() => {
              handleMenuClick(menuKey)
            }}>
            {intl.get(getIntlKey(menuKey)) || menuKey}
          </div>
        )}
        <div onClick={() => {
          setVisible(false)
        }}>
          <img alt="" src={menuClose} />
        </div>
      </div>
    </Mask>
  }

  return (
    <div className="mobile-menu" onClick={() => { setVisible(true) }}>
      {!visible ? <img alt="" src={menuBtn} /> : null}
      <MenuList />
    </div>
  )
}

export default MobileMenu;
