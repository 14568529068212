import { Toast } from "antd-mobile";
import localStorage from "localStorage";
import axios from '../service/request';
import { sleep } from "../util/common";
import { getLanguage } from "@/util/language";
const RedeemModel = {
  namespace: "redeem",

  state: {
    startInterval: false,
    currentRedeemId: 0,
    redeemList: [],
    activity: {},
    transferStatus: false,
    transferUsdtStatus: false,
    startIntervalNft: false,
    startIntervalUsdt: false,
  },

  effects: {
    *getRedeemList({ payload }, { call, put }) {
      let address = "";
      if (JSON.stringify(payload) == "{}" || payload == undefined) {
        if (window.ethereum != undefined) {
          const accounts = yield window.ethereum.request({
            method: "eth_requestAccounts",
          });
          address = accounts[0];
        }
      } else {
        address = payload.address;
      }
      if (!address) {
        let wcStr = "";
        try {
          wcStr = localStorage.getItem("walletconnect");
        } catch (error) {
          // console.log("local storage get wallet connect info error");
        }
        if (wcStr) {
          let wcObj = JSON.parse(wcStr);
          address = wcObj["accounts"][0];
        }
      }
      if (!address) {
        // console.log('final address = "" ');
        return Toast.success("pls Connect Wallet");
      }
      //axios get activity
      const language = getLanguage();
      let res = yield call(axios.get, "/activity/9", {
        headers: { language },
      });
      if (res && res.code == 0 && res.data) {
        // console.log(res);
        yield put({
          type: "setState",
          payload: { activity: res.data },
        });
      }
      //axios get redeem list
      let resRedeemList = yield call(axios.get, `/redeem/my/${address}`);
      if (resRedeemList && resRedeemList.code == 0 && resRedeemList.data) {
        // console.log("redeem list: ", resRedeemList.data);
        yield put({
          type: "setState",
          payload: { redeemList: resRedeemList.data },
        });
      }
    },

    *getRedeemDetail({ payload }, { call, put }) {
      if (!payload || payload.currentRedeemId == 0) {
        return // console.log("currentRedeemId == 0 return");
      }
      let resRedeemDetail = yield call(
        axios.get,
        `/redeem/${payload.currentRedeemId}`
      );
      if (
        resRedeemDetail &&
        resRedeemDetail.code == 0 &&
        resRedeemDetail.data
      ) {
        let data = resRedeemDetail.data;
        // console.log("getRedeemDetail data: ", data);
        let payloadData = {
          activity: { ...resRedeemDetail.data },
          transferStatus: data.tokenIdStatus == "success" ? true : false,
          transferUsdtStatus: data.payed == "success" ? true : false,
        };
        // console.log("getRedeemDetail payloadData: ", payloadData);
        yield put({
          type: "setState",
          payload: payloadData,
        });
      }
    },

    *start({ payload }, { call, put, select }) {
      // console.log("exec redeem loop");
      if (!payload || !payload.currentRedeemId) {
        return // console.log("start interval currentRedeemId wrong");
      }
      yield put({
        type: "setState",
        payload: {
          startInterval: true,
          currentRedeemId: payload.currentRedeemId,
        },
      });

      const tmpRedeemId = yield select(({ redeem }) => {
        return redeem.currentRedeemId;
      });
      while (true) {
        // console.log("enter");
        let tmp2RedeemId = yield select(({ redeem }) => redeem.currentRedeemId);
        // console.log("tmpRedeemId; ", tmpRedeemId);
        // console.log("current tmp2RedeemId: ", tmp2RedeemId);
        if (tmpRedeemId != tmp2RedeemId || tmp2RedeemId == 0) {
          // console.log("redeem loop stop");
          break;
        }
        const tmpTransferStatus = yield select(
          ({ redeem }) => redeem.transferStatus
        );
        const tmpTransferUsdtStatus = yield select(
          ({ redeem }) => redeem.transferUsdtStatus
        );
        // console.log("tmpTransferStatus: ", tmpTransferStatus);
        if (tmpTransferStatus && tmpTransferUsdtStatus) {
          // console.log("both status success, return");
          break;
        }
        let resRedeemDetail = yield call(
          axios.get,
          `/redeem/${payload.currentRedeemId}`
        );
        if (
          resRedeemDetail &&
          resRedeemDetail.code == 0 &&
          resRedeemDetail.data
        ) {
          let data = resRedeemDetail.data;

          yield put({
            type: "setState",
            payload: {
              activity: { ...resRedeemDetail.data },
              transferStatus: data.tokenIdStatus == "success" ? true : false,
              transferUsdtStatus: data.payed == "success" ? true : false,
              startIntervalNft: data.tokenIdStatus == "success" ? false : true,
              startIntervalUsdt:
                data.payed == "success"
                  ? false
                  : data.tokenIdStatus == "success"
                  ? true
                  : false,
            },
          });
        }
        yield call(sleep, 5000);
      }
    },

    *stop({ payload }, { call, put }) {
      // console.log("exec stop ");
      yield put({
        type: "setState",
        payload: {
          startInterval: false,
          currentRedeemId: 0,
          startIntervalNft: false,
          startIntervalUsdt: false,
        },
      });
    },

    *stateClear({ payload }, { call, put }) {
      yield put({
        type: "setState",
        payload: {
          startInterval: false,
          currentRedeemId: 0,
          transferStatus: false,
          transferUsdtStatus: false,
        },
      });
    },
  },

  reducers: {
    setState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default RedeemModel;