import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";　
import localStorage from "localStorage";

export function MyWalletConnect(){
  let tmpConnector = new WalletConnect({
    bridge: "https://bridge.walletconnect.org", // Required
    qrcodeModal: QRCodeModal,
  });
  // console.log('connect wallet status: ', tmpConnector.connected)
  this.instance = null;
  return tmpConnector;
}

MyWalletConnect.getInstance = (function(){
  var instance;
  return function(){
      if (this.instance && this.instance.connected && localStorage.getItem("walletconnect")) {
        return this.instance
      }
      this.instance = new MyWalletConnect();
      return this.instance;
  }
})();