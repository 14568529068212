import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import { MyWalletConnect } from "../myWalletConnect/myWalletConnect";

// Create a connector


export const sendTx = (loginType, tx) => {
  if (loginType === 'metamask' || loginType === '') {
    return window.ethereum
      .request({
        method: "eth_sendTransaction",
        params: [tx],
      })

  } else {

    let connector = MyWalletConnect.getInstance();
    // console.log('wallet connector: ', connector)
    // Check if connection is already established
    if (!connector.connected) {
      // console.log('wallect connect established')
      // create new session
      connector.createSession();
    }
    // console.log('enter sendTx by wallect')
    return connector.sendTransaction(tx)
  }
}